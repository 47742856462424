<template>
  <CardLayoutV2 title="Kudos Chart" :loading="loading">
    <KudosChart :chart-data="chartData" />
    <v-skeleton-loader
      :loading="loading"
      type="table-thead, table-row-divider@7"
    >
      <v-data-table
        :headers="tableData.headers"
        :items="tableData.data"
        :items-per-page="7"
        class="elevation-1"
      >
        <template #item.name="{ item }">
          <div class="d-flex align-center">
            <a @click="() => goToFacilityDashboard(item)">{{ item.name }}</a>
            <v-icon v-if="item.hasTrophy" color="#FFD43B">
              fa-solid fa-trophy
            </v-icon>
          </div>
        </template>
        <template #body.append>
          <tr class="total">
            <td></td>
            <td style="font-weight: bold">Total</td>
            <td style="font-weight: bold">
              {{ tableData.totalTodaySubscriptions }}
            </td>
            <td style="font-weight: bold">
              {{ tableData.totalPreviousDaySubscriptions }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </CardLayoutV2>
</template>

<script>
import CardLayoutV2 from "@layout/CardLayoutV2.vue";
import { DAILY_SUBSCRIPTIONS } from "./graphql";
import KudosChart from "./KudosChart.vue";
import { formatToISODate } from "@tod-ui/helpers/datetime";
import { calculateTotal } from "@tod-ui/helpers/math";
import { CHART_COLORS } from "@tod-ui/constants/colors";
import useFacility from "@components/authentication/useFacility";

export default {
  components: {
    CardLayoutV2,
    KudosChart,
  },
  props: {
    selectedFacilities: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { selectFacility } = useFacility();
    return {
      selectFacility,
    };
  },
  data() {
    return {
      dailySubscriptions: [],
    };
  },
  computed: {
    loading() {
      return this.$apollo.queries.dailySubscriptions.loading;
    },
    currentDate() {
      const date = new Date().toISOString();
      return formatToISODate(date);
    },
    calculateDailySubscriptionSums() {
      return this.dailySubscriptions.reduce(
        (acc, subscription) => {
          if (subscription.addsOnDate) {
            acc.today += subscription.addsOnDate;
          }
          if (subscription.addsPrevDay) {
            acc.yesterday += subscription.addsPrevDay;
          }
          return acc;
        },
        { today: 0, yesterday: 0 }
      );
    },
    chartData() {
      const todayValue = this.calculateDailySubscriptionSums.today;
      const yesterdayValue = this.calculateDailySubscriptionSums.yesterday;

      const data = [
        { label: "Today", value: todayValue },
        { label: "Yesterday", value: yesterdayValue },
      ];

      data.sort((a, b) => b.value - a.value);

      return {
        backgroundColors: {
          Today: CHART_COLORS[0],
          Yesterday: CHART_COLORS[1],
        },
        data,
      };
    },
    tableData() {
      // Calculate the facility with the highest subscriptions yesterday
      const highestYesterdayValue = Math.max(
        ...this.dailySubscriptions.map((facility) => facility.addsPrevDay)
      );

      let facilitiesWithHighestYesterday = [];

      if (highestYesterdayValue > 0) {
        facilitiesWithHighestYesterday = this.dailySubscriptions.filter(
          (facility) => facility.addsPrevDay === highestYesterdayValue
        );
      }

      return {
        headers: [
          {
            text: "",
            align: "start",
            sortable: false,
            value: "rowNumber",
          },
          {
            text: "Facility",
            align: "start",
            sortable: false,
            value: "name",
          },
          { text: "Today", value: "today" },
          { text: "Yesterday", value: "yesterday" },
        ],
        data: this.dailySubscriptions.map((facility, index) => {
          return {
            rowNumber: index + 1,
            id: facility.facilityId,
            name: facility.facilityName,
            today: facility.addsOnDate,
            yesterday: facility.addsPrevDay,
            hasTrophy: facilitiesWithHighestYesterday.includes(facility),
          };
        }),
        totalTodaySubscriptions: calculateTotal(
          this.dailySubscriptions,
          "addsOnDate"
        ),
        totalPreviousDaySubscriptions: calculateTotal(
          this.dailySubscriptions,
          "addsPrevDay"
        ),
      };
    },
  },
  apollo: {
    dailySubscriptions: {
      query: DAILY_SUBSCRIPTIONS,
      variables() {
        return {
          facilityIds: this.selectedFacilities.map((f) => f.id),
          reportDate: this.currentDate,
        };
      },
      skip() {
        return !this.selectedFacilities.length;
      },
    },
  },
  methods: {
    goToFacilityDashboard(item) {
      this.selectFacility(this.$apollo, item);
      this.$router.push({
        name: "Facility",
        params: {
          organization_id: item.id,
        },
      });
    },
  },
};
</script>
