import { DECLINE_RED, INCLINE_GREEN } from "@tod-ui/constants/colors";
import { ROLLING_AVG } from "@tod-ui/constants/dashboard";
import {
  formatMonthYear,
  getFormattedMonthName,
} from "@tod-ui/helpers/datetime";
import { roundValue } from "@tod-ui/helpers/math";
import { toLowerCaseAndRemoveSpaces } from "@tod-ui/helpers/strings";

export const conversionRate = "conversionRate";

export function calculateConversionRate(totalAddedSubscriptions, totalMoveIns) {
  if (totalMoveIns === 0) return 0;
  else {
    return roundValue((totalAddedSubscriptions / totalMoveIns) * 100);
  }
}

export function calculatePenetrationRate(totalActiveSubscriptions, totalUnits) {
  if (totalUnits === 0) return 0;
  else {
    return roundValue((totalActiveSubscriptions / totalUnits) * 100);
  }
}
/**
 * Groups data by month and sums the specified result key for each month.
 *
 * @param {Array} data - The data to be grouped. Each element should have a dateKey (in YYYY-MM format).
 * @param {string} dateKey - The key in each element of the data array that contains the date information.
 * @param {string} resultKey - The key in each element of the data array whose values should be summed.
 * @returns {Object} An object containing 'chartData' and 'chartMonths' arrays.
 */
export function groupDataByMonth(data, dateKey, resultKey, sortOrder = "asc") {
  const groupedData = {};

  data.forEach((facility) => {
    const month = facility[dateKey].slice(0, 7);

    if (!groupedData[month]) {
      groupedData[month] = {
        month,
        [resultKey]: 0,
        facilityCount: 0,
        currentActiveSubscriptions: 0,
        totalMoveIns: 0,
      };
    }

    groupedData[month].facilityCount += 1;

    if (resultKey === conversionRate) {
      groupedData[month].currentActiveSubscriptions +=
        facility["subscriptionsAdded"];
      groupedData[month].totalMoveIns += facility["totalMoveIns"];
    } else {
      groupedData[month][resultKey] += facility[resultKey];
    }
  });

  // Convert the grouped data object to an array and sort it by month
  const sortedData = Object.values(groupedData).sort((a, b) => {
    if (sortOrder === "dsc") {
      return new Date(b.month) - new Date(a.month);
    } else {
      return new Date(a.month) - new Date(b.month);
    }
  });

  // Adjust conversionRate using the provided formula
  if (resultKey === conversionRate) {
    sortedData.forEach((entry) => {
      entry[resultKey] = calculateConversionRate(
        entry.currentActiveSubscriptions,
        entry.totalMoveIns
      );
    });
  }

  const chartData = sortedData.map((entry) => entry[resultKey]);
  const chartMonths = sortedData.map((entry) => entry.month);

  return {
    chartData,
    chartMonths,
  };
}

export function comparisonPercentage(currentMonthData, comparisonMonthData) {
  if (currentMonthData === 0 && comparisonMonthData === 0) {
    return 0;
  } else if (comparisonMonthData === 0) {
    return "-";
  }
  const percentage =
    ((currentMonthData - comparisonMonthData) / comparisonMonthData) * 100;

  return roundValue(percentage);
}

// Utility function to calculate comparison percentage
export function calculateComparisonPercentage(data, comparisonMonth) {
  const currentMonthData = data.conversionRate
    ? data.conversionRate
    : data.currentMonth;
  const normalizedComparisonMonth = toLowerCaseAndRemoveSpaces(comparisonMonth);
  const comparisonMonthData = data[normalizedComparisonMonth];
  return comparisonPercentage(
    roundValue(currentMonthData),
    roundValue(comparisonMonthData)
  );
}

// Function to initialize facility data structure
function initializeFacilityData(current, monthSet) {
  const facilityData = {
    facilityId: current.facilityId,
    facilityName: current.facilityName,
    region: current.region,
    currentMonth: 0,
    subscriptionsAdded: 0,
    totalMoveIns: 0,
  };
  // Initialize months with 0
  monthSet.forEach((month) => {
    facilityData[toLowerCaseAndRemoveSpaces(month)] = 0;
  });

  return facilityData;
}

// Function to populate data for each facility
function populateData(
  facilityMap,
  data,
  key,
  monthSet,
  currentMonth,
  monthKey
) {
  data.forEach((current) => {
    const formattedMonth = formatMonthYear(current[monthKey]);
    const facilityId = current.facilityId;

    if (!facilityMap.has(facilityId)) {
      facilityMap.set(facilityId, initializeFacilityData(current, monthSet));
    }

    const facility = facilityMap.get(facilityId);
    facility[toLowerCaseAndRemoveSpaces(formattedMonth)] = current[key] || 0;
    if (toLowerCaseAndRemoveSpaces(formattedMonth) === currentMonth) {
      facility.currentMonth = roundValue(current[key] || 0);
      if (key === conversionRate) {
        facility.subscriptionsAdded = current["subscriptionsAdded"];
        facility.totalMoveIns = current["totalMoveIns"];
      }
    }
  });
}

// Utility function to calculate three months rolling average
function calculateRollingAverage(facilityMap, monthSet) {
  facilityMap.forEach((facility) => {
    const last3Months = Array.from(monthSet.values()).slice(-3); // Get last three months
    let sum = 0;
    let count = 0;

    last3Months.forEach((month) => {
      const formattedMonth = toLowerCaseAndRemoveSpaces(month);
      if (facility.hasOwnProperty(formattedMonth)) {
        sum += facility[formattedMonth];
        count++;
      }
    });
    facility[ROLLING_AVG] = roundValue(count > 0 ? sum / count : 0);
  });
}

// Main function to transform monthly trends data
export function transformMonthlyTrendsData(data, key, months, monthKey) {
  const monthSet = new Set(months.map(formatMonthYear));
  const currentMonth = toLowerCaseAndRemoveSpaces(getFormattedMonthName(0, 0));
  const facilityMap = new Map();
  populateData(facilityMap, data, key, monthSet, currentMonth, monthKey);
  calculateRollingAverage(facilityMap, monthSet);
  return Array.from(facilityMap.values());
}

// Helper function for building organization tree
export function buildOrganizationHierarchy(organizations) {
  const parentMap = new Map();
  const hierarchicalData = [];

  organizations.forEach((org) => {
    parentMap.set(org.id, org);
    org.children = [];
  });

  organizations.forEach((org) => {
    if (org.parentId && parentMap.has(org.parentId)) {
      const parent = parentMap.get(org.parentId);
      parent.children.push(org);
    } else {
      hierarchicalData.push(org);
    }
  });

  return hierarchicalData;
}
// Helper function for regional view report
export function groupByRegion(report, fields) {
  const groupedDataMap = new Map();

  report.forEach((item) => {
    const regionPath = item.region;

    if (!groupedDataMap.has(regionPath)) {
      const newItem = { organizationPath: regionPath };

      fields.forEach((field) => {
        newItem[field] = 0;
      });

      groupedDataMap.set(regionPath, newItem);
    }

    const currentItem = groupedDataMap.get(regionPath);

    fields.forEach((field) => {
      currentItem[field] += item[field] || 0;
    });
  });

  return Array.from(groupedDataMap.values());
}

export function getColorByPercentage(percentage) {
  if (percentage <= 10) {
    return DECLINE_RED;
  } else if (percentage <= 50) {
    return "yellow";
  } else {
    return INCLINE_GREEN;
  }
}
