<template>
  <LayoutPage>
    <template #title>
      <v-toolbar-title class="headline">
        {{ `Grace Combs Management Dashboard` }}
      </v-toolbar-title>
    </template>
    <div
      :style="{
        position: 'sticky',
        top: '65px',
        zIndex: 4,
        backgroundColor: 'white',
        padding: '0 10px',
      }"
    >
      <v-row>
        <v-col cols="3">
          <v-select
            v-model="selectedRegionIds"
            :items="regions"
            label="Select Regions"
            outlined
            item-text="name"
            item-value="id"
            multiple
            dense
          >
            <template #selection="{ item, index }">
              <span v-if="index === 0">{{ item }}</span>
              <span v-if="index === 1" class="grey--text caption"
                >(+{{ selectedRegionIds.length - 1 }} others)</span
              >
            </template>
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="selectedFacilities"
            :items="facilities"
            label="Select Facilities"
            multiple
            outlined
            clearable
            item-text="name"
            item-value="id"
            dense
          >
            <template #selection="{ item, index }">
              <span v-if="index === 0">{{ item.name }}</span>
              <span v-if="index === 1" class="grey--text caption"
                >(+{{ selectedFacilities.length - 1 }} others)</span
              >
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="3" />
        <v-col cols="3">
          <v-select
            v-model="dashboard"
            :items="dashboards"
            label="Select Dashboard"
            outlined
            item-text="name"
            item-value="id"
            dense
          />
        </v-col>
      </v-row>
    </div>
    <CardDashboardTiles />
    <v-row>
      <v-col cols="12" md="6">
        <DashboardLayout title="Revenue (YTD)" color="#D50D41">
          <LineChart :chart-data="totalRevenueThisYear" unit="$" />
          <v-row>
            <v-col cols="5">
              <MTDTile
                heading="MTD Revenue"
                top-value="4,920"
                top-unit="$"
                avatar-name="revenue"
                :is-shadow="false"
                @topClickEvent="goToRevenueTable"
              />
            </v-col>
            <v-col cols="7">
              <ComparisonTile
                heading="MTD Comparison"
                top-title="June 2023"
                top-value="$3,876"
                bottom-title="May 2024"
                bottom-value="$4,920"
                :top-value-change-percent="21"
                top-value-change="down"
                :is-vs="true"
                height="130px"
                :is-shadow="false"
                @topClickEvent="goToRevenueTable"
                @bottomClickEvent="goToRevenueTable"
              />
            </v-col>
          </v-row>
        </DashboardLayout>
      </v-col>
      <v-col cols="12" md="6">
        <DashboardLayout title="Conversion (YTD)" color="#323487">
          <LineChart :chart-data="averageConversionPercentage" unit="%" />
          <v-row>
            <v-col cols="5">
              <MTDTile
                heading="MTD Conversion"
                :top-value="35"
                top-unit="%"
                avatar-name="conversion"
                :is-shadow="false"
                @topClickEvent="goToConversionTable"
              />
            </v-col>
            <v-col cols="7">
              <ComparisonTile
                heading="MTD Comparison"
                top-title="June 2023"
                top-value="36%"
                bottom-title="May 2024"
                bottom-value="24%"
                :is-vs="true"
                height="130px"
                :is-shadow="false"
                @topClickEvent="goToConversionTable"
                @bottomClickEvent="goToConversionTable"
              />
            </v-col>
          </v-row>
        </DashboardLayout>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DashboardLayout title="Monthly New Subscriptions" color="#323487">
          <BarChart :chart-data="totalMonthlyNewSubscriptionsChartData" />
        </DashboardLayout>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <DashboardLayout title="All Active Subscriptions" color="#D50D41">
          <DoughnutChart :chart-data="allActiveSubscriptionsChartData" />
          <v-data-table
            :headers="allActiveSubscriptionsTableData.headers"
            :items="allActiveSubscriptionsTableData.data"
            :items-per-page="5"
            class="elevation-1"
          >
            <template #item.name="{ item }">
              <a @click="() => goToFacilityDashboard(item)">{{ item.name }}</a>
            </template>
            <template #body.append>
              <tr class="total">
                <td></td>
                <td style="font-weight: bold">Total</td>
                <td style="font-weight: bold">
                  {{ allActiveSubscriptionsTableData.totalSubscriptions }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </DashboardLayout>
      </v-col>
      <v-col cols="12" md="6">
        <DashboardLayout title="MTD New Subscribers" color="#323487">
          <DoughnutChart :chart-data="MTDNewSubscribersChartData" />
          <v-data-table
            :headers="MTDNewSubscribersTableData.headers"
            :items="MTDNewSubscribersTableData.data"
            :items-per-page="5"
            class="elevation-1"
          >
            <template #item.name="{ item }">
              <a @click="() => goToFacilityDashboard(item)">{{ item.name }}</a>
            </template>
            <template #body.append>
              <tr class="total">
                <td></td>
                <td style="font-weight: bold">Total</td>
                <td style="font-weight: bold">
                  {{ MTDNewSubscribersTableData.totalSubscriptions }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </DashboardLayout>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <DashboardLayout title="Kudos Chart" color="#323487">
          <AverageVisitChart
            :chart-data="MTDSubscribersPieChart"
            :is-kudo="true"
          />

          <v-data-table
            :headers="MTDSubscribersPieChartTableData.headers"
            :items="MTDSubscribersPieChartTableData.data"
            :items-per-page="5"
            class="elevation-1"
          >
            <template #item.name="{ item, index }">
              <v-container class="d-flex">
                <a @click="() => goToFacilityDashboard(item)">{{
                  item.name
                }}</a>
                <div
                  v-if="index === 0 || index === 1"
                  class="ml-2"
                  style="height: 20px; width: 20px"
                >
                  <v-icon color="#FFD43B">fa-solid fa-trophy</v-icon>
                </div>
              </v-container>
            </template>
            <template #body.append>
              <tr class="total">
                <td></td>
                <td style="font-weight: bold">Total</td>
                <td style="font-weight: bold">
                  {{ MTDSubscribersPieChartTableData.todayTotalSubscriptions }}
                </td>
                <td style="font-weight: bold">
                  {{
                    MTDSubscribersPieChartTableData.yesterdayTotalSubscriptions
                  }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </DashboardLayout>
      </v-col>
      <v-col cols="12" md="6">
        <DashboardLayout
          title="Average Duration Per Visit (MTD)"
          color="#D50D41"
        >
          <div style="cursor: pointer">
            <AverageVisitChart :chart-data="averageDurationPerVisitsChart" />
          </div>
        </DashboardLayout>
        <DashboardLayout title="Average # of Visits (MTD)" color="#323487">
          <div style="cursor: pointer">
            <AverageVisitChart :chart-data="averageVisitChart" />
          </div>
        </DashboardLayout>
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import CardDashboardTiles from "./CardDashboardTiles.vue";
import DoughnutChart from "./DoughnutChart.vue";
import BarChart from "./BarChart.vue";
import LineChart from "./LineChart.vue";
import LayoutPage from "@layout/LayoutPage.vue";
import useDashboard from "./useDashboard";
import AverageVisitChart from "./AverageVisitChart.vue";
import { facilityData } from "./data";
import MTDTile from "./MTDTile.vue";
import ComparisonTile from "./ComparisonTile.vue";
import DashboardLayout from "../DashboardLayout.vue";

export default {
  name: "PageDemoManagementDashboard",
  components: {
    LayoutPage,
    CardDashboardTiles,
    DoughnutChart,
    BarChart,
    LineChart,
    AverageVisitChart,
    MTDTile,
    ComparisonTile,
    DashboardLayout,
  },
  setup() {
    const {
      selectedFacilityIds,
      organizationDescendantsLoading,
      filteredFacilities,
      allActiveSubscriptionsChartData,
      allActiveSubscriptionsTableData,
      dashboardLoading,
      totalRevenueThisYear,
      averageConversionPercentage,
      totalMonthlyNewSubscriptionsChartData,
      MTDNewSubscribersChartData,
      MTDNewSubscribersTableData,
      dashboardData,
      averageDurationPerVisitsChart,
      averageVisitChart,
      MTDSubscribersPieChart,
      MTDSubscribersPieChartTableData,
    } = useDashboard();
    return {
      selectedFacilityIds,
      organizationDescendantsLoading,
      filteredFacilities,
      allActiveSubscriptionsChartData,
      allActiveSubscriptionsTableData,
      dashboardLoading,
      totalRevenueThisYear,
      averageConversionPercentage,
      totalMonthlyNewSubscriptionsChartData,
      MTDNewSubscribersChartData,
      MTDNewSubscribersTableData,
      dashboardData,
      averageDurationPerVisitsChart,
      averageVisitChart,
      MTDSubscribersPieChart,
      MTDSubscribersPieChartTableData,
    };
  },
  data() {
    return {
      dashboards: [
        { id: 1, name: "Management Summary" },
        { id: 2, name: "Monitoring & Control Hub" },
        { id: 3, name: "Operations Summary" },
      ],
      dashboard: 1,
      selectedRegion: "Grace Combs",
      regions: ["Grace Combs", "Region 2", "Region 3"],
      selectedRegionIds: ["Grace Combs"],
      selectedFacilities: [
        { id: 1, name: "Facility 1" },
        { id: 2, name: "Facility 2" },
        { id: 3, name: "Facility 3" },
      ],
      facilities: [
        { id: 1, name: "Facility 1" },
        { id: 2, name: "Facility 2" },
        { id: 3, name: "Facility 3" },
      ],
    };
  },
  watch: {
    filteredFacilities(newFacilities) {
      this.selectedFacilityIds = newFacilities.map((facility) => facility.id);
    },
    selectedFacilityIds(facilityIds) {
      this.dashboardData = facilityData.filter((facility) =>
        facilityIds.includes(facility.id)
      );
    },
    dashboard(newDashboard) {
      if (newDashboard === 2) {
        this.$router.push({ path: "/demo/call-center" });
      }
      if (newDashboard === 3) {
        this.$router.push({ path: "/demo/operation-and-security" });
      }
    },
  },
  methods: {
    goToRevenueTable(value) {
      this.$router.push(`/demo/management/mtd-revenue/${value}`);
    },
    goToConversionTable(value) {
      if (value === "mtdconversion") value = "conversionPercentage";
      this.$router.push(`/demo/management/conversion/${value}`);
    },
    goToFacilityDashboard(item) {
      this.$router.push({
        name: "Facility",
        params: {
          organization_id: item.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.top-red-text {
  margin-left: 9px;
  margin-bottom: 3px;
  font-size: 12px;
  color: red;
}

.top-green-text {
  margin-left: 9px;
  margin-bottom: 3px;
  font-size: 12px;
  color: green;
  color: rgba(77, 85, 202, 0.8);
}
</style>
