<template>
  <v-card outlined flat tile>
    <v-card-title class="subtitle-1">
      <v-spacer />
      <v-row dense justify="end" align="center">
        <v-col cols="12" xs="12" md="5">
          <v-select
            v-model="selectedZone"
            :disabled="loading"
            placeholder="View traffic for..."
            :items="demoSmartZones"
            item-text="smart_zones"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <DateTimeFilter
            v-model="date"
            :ranges="['Today', '2d', '1m']"
            default="1m"
            custom
          />
        </v-col>
      </v-row>
      <v-progress-linear :active="loading" bottom height="2" indeterminate />
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader :loading="loading" type="image, image" height="300px">
        <BarChart :chart-data="chartData" />
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import BarChart from "@components/demo_dashboard/management_dashboard/BarChart.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import { demoSmartZones } from "./PageDemoSmartZones.vue";

export default {
  name: "ZoneTrafficReport",
  components: {
    BarChart,
    DateTimeFilter,
  },
  setup() {
    return { demoSmartZones };
  },

  data: () => ({
    date: { after: undefined, before: undefined },
    chartData: {
      labels: [
        "13 May",
        "14 May",
        "15 May",
        "16 May",
        "17 May",
        "18 May",
        "19 May",
        "20 May",
        "21 May",
        "22 May",
        "23 May",
        "24 May",
        "25 May",
        "26 May",
        "27 May",
        "28 May",
        "29 May",
        "30 May",
        "31 May",
        "01 Jun",
        "02 Jun",
        "03 Jun",
        "04 Jun",
        "05 Jun",
        "06 Jun",
        "07 Jun",
        "08 Jun",
        "09 Jun",
        "10 Jun",
        "11 Jun",
        "12 Jun",
        "13 Jun",
        "14 Jun",
      ],
      datasets: [
        {
          label: "Zone Traffic",
          data: [
            42, 45, 58, 65, 67, 59, 75, 75, 52, 65, 70, 77, 65, 75, 70, 80, 66,
            77, 60, 65, 67, 60, 65, 70, 65, 60, 65, 70, 75, 80, 70, 75, 80,
          ],
          backgroundColor: "rgba(77, 85, 202, 0.8)",
        },
      ],
    },
    zoneTraffic: [],
    chartMin: 0,
    chartMax: 6,
    bucketsPerDay: 24,
    zoneOptions: [],
    smartZones: [],
    selectedZone: demoSmartZones[0],
    loading: true,
  }),
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>
