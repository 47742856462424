<template>
  <v-card
    class="tile-card pa-1"
    :style="{ height: height }"
    :elevation="isShadow ? 5 : 0"
    outlined
  >
    <p v-if="heading" class="mb-3 text-center">
      {{ heading }}
    </p>
    <p
      v-if="headValue"
      class="text-h4 cursor-pointer"
      @click="emitHeadClickEvent"
    >
      {{ headValue }}
    </p>
    <div class="flex-container">
      <div>
        <p v-if="topTitle">
          {{ topTitle }}
          <v-tooltip v-if="topTooltip" bottom color="black">
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon small>mdi-information</v-icon>
              </span>
            </template>
            <span>{{ topTooltip }}</span>
          </v-tooltip>
        </p>
        <div :class="arrowAlignClass">
          <p
            v-if="topValue"
            :style="{ color: topColor }"
            class="value-container cursor-pointer"
            @click="emitTopClickEvent"
          >
            {{ topValueWithUnit }}
          </p>
          <span v-if="topValueChange" class="ml-3">
            <IconArrow
              :color="topValueChange === 'up' ? '#2AAA8A' : '#FB9E85'"
              :direction="topValueChange"
            >
              <span v-if="topValueChangePercent" class="percentage">
                {{ topValueChangePercent }}%
              </span>
            </IconArrow>
          </span>
        </div>
      </div>
      <div>
        <p v-if="bottomTitle">
          {{ bottomTitle }}
          <v-tooltip v-if="bottomTooltip" bottom color="black">
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon small>mdi-information</v-icon>
              </span>
            </template>
            <span>{{ bottomTooltip }}</span>
          </v-tooltip>
        </p>
        <div :class="arrowAlignClass" @click="emitBottomClickEvent">
          <p
            v-if="bottomValue"
            :style="{ color: bottomColor }"
            class="cursor-pointer value-container"
          >
            {{ bottomValueWithUnit }}
          </p>
          <div v-if="bottomValueChange" class="ml-3 d-flex">
            <IconArrow
              :color="bottomValueChange === 'up' ? '#2AAA8A' : '#FB9E85'"
              :direction="bottomValueChange"
            >
              <p v-if="bottomValueChangePercent" class="percentage">
                {{ bottomValueChangePercent }}%
              </p>
            </IconArrow>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="headValue"
      :style="{ height: '10px', width: '100%', backgroundColor: avatarColor }"
    ></div>
  </v-card>
</template>

<script>
import useDashboard from "./useDashboard";
import IconArrow from "../IconArrow.vue";

export default {
  name: "CardDashboardTile",
  components: {
    IconArrow,
  },
  props: {
    heading: {
      type: String,
      default: "",
    },
    headValue: {
      type: [String, Number],
      default: "",
    },
    isShadow: {
      type: Boolean,
      default: true,
    },
    topTitle: {
      type: String,
      default: "",
    },
    topColor: {
      type: String,
      default: "black",
    },
    bottomColor: {
      type: String,
      default: "black",
    },
    bottomTitle: {
      type: String,
      default: "",
    },
    topTooltip: {
      type: String,
      default: "",
    },
    bottomTooltip: {
      type: String,
      default: "",
    },
    topValue: {
      type: [String, Number],
      default: "",
    },
    bottomValue: {
      type: [String, Number],
      default: "",
    },
    topUnit: {
      type: String,
      default: "",
    },
    bottomUnit: {
      type: String,
      default: "",
    },
    topValueChange: {
      type: String,
      default: "",
    },
    topValueChangePercent: {
      type: [String, Number],
      default: "",
    },
    bottomValueChange: {
      type: String,
      default: "",
    },
    bottomValueChangePercent: {
      type: [String, Number],
      default: "",
    },
    headClickEvent: {
      type: String,
      default: "",
    },
    topClickEvent: {
      type: String,
      default: "",
    },
    bottomClickEvent: {
      type: String,
      default: "",
    },
    avatarColor: {
      type: String,
      default: "",
    },
    isVs: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "auto",
    },
  },
  setup() {
    const { dashboardLoading, organizationDescendantsLoading } = useDashboard();
    return {
      dashboardLoading,
      organizationDescendantsLoading,
    };
  },
  computed: {
    topValueWithUnit() {
      return this.topUnit === "$"
        ? `${this.topUnit} ${this.topValue}`
        : `${this.topValue} ${this.topUnit}`;
    },
    bottomValueWithUnit() {
      return this.bottomUnit === "$"
        ? `${this.bottomUnit} ${this.bottomValue}`
        : `${this.bottomValue} ${this.bottomUnit}`;
    },
    arrowAlignClass() {
      return this.topValueChangePercent
        ? "arrowAlign-column"
        : "arrowAlign-row";
    },
  },
  methods: {
    emitHeadClickEvent() {
      let value = this.heading ? this.heading : this.heading;
      value = value.replace(/\s/g, "").toLowerCase();
      this.$emit("headClickEvent", value);
    },
    emitTopClickEvent() {
      let value = this.topTitle ? this.topTitle : this.heading;
      if (this.heading === "Open Tasks") {
        value = "opentaskcurrent";
      } else if (this.heading === "Open Incidents") {
        value = "openincidentcurrent";
      } else if (this.heading === "In Progress Incidents") {
        value = "inreview";
      }
      value = value.replace(/\s/g, "").toLowerCase();
      this.$emit("topClickEvent", value);
    },
    emitBottomClickEvent() {
      let value = this.bottomTitle ? this.bottomTitle : this.heading;
      if (this.heading === "Open Tasks") {
        value = "opentaskoverdue";
      } else if (this.heading === "Open Incidents") {
        value = "openincidentoverdue";
      } else if (this.heading === "In Progress Incidents") {
        value = "overdueinprogressincidents";
      }
      value = value.replace(/\s/g, "").toLowerCase();
      this.$emit("bottomClickEvent", value);
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.tile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrowAlign-column {
  display: flex;
  /* flex-direction: column-reverse; */
  align-items: center;
}

.arrowAlign-row {
  display: flex;
  align-items: center;
}
.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.value-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.vs-text {
  flex-shrink: 0;
}

@media (max-width: 600px) {
  .flex-container {
    flex-direction: column;
    height: auto;
    gap: 10px;
  }
  .vs-text {
    margin: 10px 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.value-container {
  font-size: medium;
  font-weight: 500;
}
.percentage {
  font-size: 1rem;
  margin-left: 5px;
}
</style>
