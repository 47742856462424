import { durationInWords } from "@tod-ui/helpers/datetime";
import { rmaRequestText } from "@tod-ui/helpers/devices";
import { titleCase } from "@tod-ui/helpers/strings";

export function unitWithAvailableDevices(unit) {
  return (
    unit?.devices?.length > 0 &&
    unit.devices.every(({ status, disposition, rmaRequest }) => {
      return ["in_service", "waiting"].includes(disposition) && !rmaRequest;
    })
  );
}

export function dispositionText(device) {
  if (shouldShowDisposition(device))
    return `Device is ${titleCase(device.disposition)}`;

  if (device.rmaRequest)
    return `Device is ${rmaRequestText(device.rmaRequest)}`;

  if (device.disposition !== "in_service")
    return `Device is ${titleCase(device.disposition)}`;

  if (device.status !== "ok") return "Unhealthy Device";
  if (!device.signalStrength || isNaN(parseFloat(device.signalStrength)))
    return "No Signal";

  return "Device is Active";
}

export function shouldShowDisposition(device) {
  return ["missing", "shipping"].includes(device.disposition);
}

export function batteryLevel(device) {
  // when battery level does not exist
  if (!device.batteryLevel) return 95;
  const value = parseFloat(device.batteryLevel);
  if (isNaN(value)) return 0;
  if (value > 100) return 100;

  return Math.round(value);
}

export function batteryLevelText(device) {
  const value = batteryLevel(device);
  return `Battery ${value}%`;
}

export function formatDeviceName(device, organizationId = null) {
  const name = device?.shortId || device?.externalId;
  return name
    ? organizationId &&
      device?.organization?.name &&
      device?.organization?.id &&
      device?.organization?.id !== organizationId
      ? `${name} (${device.organization.name})`
      : name
    : "";
}

export function deviceLastMotion(device) {
  return device.motionStartedAt
    ? `${durationInWords(device.motionStartedAt)} ago`
    : "--";
}
export function extractDevicesFields(devices) {
  const devicesName = [],
    devicesHealth = [],
    devicesLastMotion = [],
    devicesLastAssignedTo = [];

  if (devices) {
    if (!Array.isArray(devices)) {
      devices = [devices];
    }
    devices.forEach((device) => {
      devicesName.push(formatDeviceName(device));
      devicesHealth.push(device);
      devicesLastMotion.push(device.lastMotion || deviceLastMotion(device));

      // let lastAssignedAt = "N/A";
      let lastAssignedTo = "N/A";
      // sort assignmentHistory descending by timestamp
      if (device?.assignmentHistory?.length) {
        device.assignmentHistory.sort((a, b) => {
          return new Date(b.timestamp) - new Date(a.timestamp);
        });
        // find the most recent assignment that is not the current assignment
        const assignment = device.assignmentHistory.find(
          ({ organizationId }) => {
            return organizationId !== device.organization.id;
          }
        );

        if (assignment) {
          // lastAssignedAt = assignment.timestamp;
          lastAssignedTo = assignment.organizationName;
        }
      }
      devicesLastAssignedTo.push(lastAssignedTo);
    });
  }

  return {
    devices,
    devicesName,
    devicesHealth,
    devicesLastMotion,
    devicesLastAssignedTo,
  };
}
