<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        Zone Traffic Last Week
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <DemoZoneTrafficReport />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header>
        Zone Incidents Report
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <DemoZoneIncidentReport />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header> Zone Climate Report </v-expansion-panel-header>
      <v-expansion-panel-content>
        <DemoZoneEnvironmentReport />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import DemoZoneEnvironmentReport from "./DemoZoneEnvironmentReport.vue";
import DemoZoneIncidentReport from "./DemoZoneIncidentReport.vue";
import DemoZoneTrafficReport from "./DemoZoneTrafficReport.vue";

export default {
  components: {
    DemoZoneEnvironmentReport,
    DemoZoneIncidentReport,
    DemoZoneTrafficReport,
  },
};
</script>
