<template>
  <CardLayoutV2 title="Monthly New Subscriptions" :loading="loading">
    <ChartMonthlyNewSubscriptions :chart-data="chartData" />
  </CardLayoutV2>
</template>

<script>
import CardLayoutV2 from "@layout/CardLayoutV2.vue";
import ChartMonthlyNewSubscriptions from "./MonthlyNewSubscriptionsChart.vue";
import { formatMonthYear } from "@tod-ui/helpers/datetime";
import { groupDataByMonth } from "../helper";
import { CHART_COLORS } from "@tod-ui/constants/colors";

export default {
  components: {
    CardLayoutV2,
    ChartMonthlyNewSubscriptions,
  },
  props: {
    monthlySubscriptions: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    chartData() {
      const { chartData, chartMonths } = groupDataByMonth(
        this.monthlySubscriptions,
        "subscriptionMonth",
        "subscriptionsCount"
      );
      return {
        labels: chartMonths.map((month) => formatMonthYear(month)),
        datasets: [
          {
            label: "Monthly New Subscriptions",
            backgroundColor: CHART_COLORS[0],
            data: chartData,
          },
        ],
      };
    },
  },
};
</script>
