import gql from "graphql-tag";
import {
  contactMethodFields,
  organizationFields,
  profileFields,
  userFields,
} from "../../graphql/fragments";

export const ZONE_RESPONDERS = gql`
  query ZoneResponders($id: ID!) {
    organizationResponders(id: $id, includeAncestors: true) {
      id
      escalationLevel
      incidentTypes
      accessHours
      type
      status
      contactMethod {
        ...ContactMethodFields
      }
      organization {
        ...OrganizationFields
      }
      user {
        ...UserFields
      }
    }
  }
  ${contactMethodFields}
  ${organizationFields}
  ${userFields}
`;

export const UPDATE_SMART_ZONE = gql`
  mutation UpdateZone(
    $id: ID!
    $name: String!
    $timeZone: String!
    $profile: InputZoneProfile!
    $subtype: String!
  ) {
    updateZone(
      id: $id
      name: $name
      timeZone: $timeZone
      subtype: $subtype
      profile: $profile
    ) {
      ...OrganizationFields
      profile {
        ...ProfileFields
      }
    }
  }
  ${organizationFields}
  ${profileFields}
`;

export const EEN_EVENTS = gql`
  query eenEvents {
    eenEvents {
      accountId
      actorId
      actorType
      creatorId
      endTimestamp
      id
      startTimestamp
      thumbnail
      type
    }
  }
`;

export const EEN_VIDEO_FEED = gql`
  query eenVideoFeed($fromTimeStamp: String!, $deviceId: String!) {
    eenVideoFeed(fromTimestamp: $fromTimeStamp, deviceId: $deviceId) {
      video
    }
  }
`;
