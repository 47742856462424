<template>
  <v-card outlined flat tile>
    <v-card-title class="pb-0 mb-0 subtitle-1">
      <v-spacer />
      <v-row dense justify="end">
        <v-col cols="12" xs="12" md="5">
          <v-select
            v-model="selectedZone"
            :disabled="loading"
            placeholder="View climate for..."
            :items="smartZones"
            item-text="name"
            item-value="id"
            single-line
            @change="selectedChanged"
          />
        </v-col>
      </v-row>
      <v-progress-linear :active="loading" bottom height="2" indeterminate />
    </v-card-title>
    <v-card-actions>
      <v-spacer />
      <DateTimeFilter
        v-model="dateRange"
        :time-zone="facilityTimeZone"
        :ranges="['Today', '1w', '1m']"
        default="1w"
        custom
        class="mt-2"
      />
    </v-card-actions>
    <v-card-text v-if="hasEvents">
      <v-skeleton-loader :loading="loading" type="image, image" height="300px">
        <ZoneEnvironmentChart
          :chart-data="chartData"
          :min="chartMin"
          :max="chartMax"
        />
      </v-skeleton-loader>
    </v-card-text>
    <v-card-text v-else>
      <em>No data available</em>
    </v-card-text>
  </v-card>
</template>

<script>
import ZoneEnvironmentChart from "./ZoneEnvironmentReportChart.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";

import { deviceTelemetryEvents } from "../../graphql/organizations/zone";
import { celsiusToFahrenheit } from "../utils/converters";
import { organizationSmartZones } from "../../graphql/organizations";
import { leanOrganization } from "../../graphql/organizations/zone";
import useFacility from "../authentication/useFacility";

export default {
  name: "ZoneEnvironmentReport",
  components: {
    ZoneEnvironmentChart,
    DateTimeFilter,
  },
  setup() {
    const { currentFacilityId, facilityTimeZone } = useFacility();
    return { currentFacilityId, facilityTimeZone };
  },
  data() {
    return {
      events: {
        tempEvents: [],
        humidityEvents: [],
      },
      selectedZone: "",
      selectedZoneData: {},
      smartZones: [],
      customStartDate: null,
      startPicker: false,
      customEndDate: null,
      endPicker: false,
      dateRange: { after: undefined },
    };
  },
  computed: {
    loading() {
      if (!this.smartZones) return true;
      return this.$apollo.queries.smartZones.loading;
    },
    hasEvents() {
      return (
        this.events &&
        (this.events.tempEvents.length > 0 ||
          this.events.humidityEvents.length > 0)
      );
    },
    chartMin() {
      return this.events.tempEvents[0].x;
    },
    chartMax() {
      return this.events.tempEvents[this.events.tempEvents.length - 1].x;
    },
    chartData() {
      if (!this.hasEvents) return;
      let ds = {
        datasets: [
          {
            label: "Temperature °F",
            data: this.events.tempEvents,
            backgroundColor: "transparent",
            borderColor: "Red",
            borderWidth: 2,
            pointBackgroundColor: "transparent",
            pointBorderColor: "Red",
            hoverRadius: 5,
            lineTension: 0.5,
            showLine: true,
            fill: false,
          },
          {
            label: "Humidity %",
            data: this.events.humidityEvents,
            backgroundColor: "transparent",
            borderColor: "Blue",
            borderWidth: 2,
            pointBackgroundColor: "transparent",
            pointBorderColor: "Blue",
            hoverRadius: 5,
            lineTension: 0.5,
            showLine: true,
            fill: false,
          },
        ],
      };
      if (this.events.htThresh) {
        ds.datasets.push({
          label: "Upper Temperature Threshold °F",
          data: this.events.htThresh,
          backgroundColor: "transparent",
          borderColor: "#ff6666",
          borderWidth: 2,
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          hoverRadius: 5,
          lineTension: 0.5,
          showLine: true,
          borderDash: [10, 10],
          fill: false,
        });
      }
      if (this.events.ltThresh) {
        ds.datasets.push({
          label: "Lower Temperature Threshold °F",
          data: this.events.ltThresh,
          backgroundColor: "transparent",
          borderColor: "#ff6666",
          borderWidth: 2,
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          hoverRadius: 5,
          lineTension: 0.5,
          showLine: true,
          borderDash: [10, 10],
          fill: false,
        });
      }
      if (this.events.hhThresh) {
        ds.datasets.push({
          label: "Upper Humidity Threshold %",
          data: this.events.hhThresh,
          backgroundColor: "transparent",
          borderColor: "#6666ff",
          borderWidth: 2,
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          hoverRadius: 5,
          lineTension: 0.5,
          showLine: true,
          borderDash: [5, 2],
          fill: false,
        });
      }
      if (this.events.lhThresh) {
        ds.datasets.push({
          label: "Lower Humidity Threshold %",
          data: this.events.lhThresh,
          backgroundColor: "transparent",
          borderColor: "#6666ff",
          borderWidth: 2,
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          hoverRadius: 5,
          lineTension: 0.5,
          showLine: true,
          borderDash: [5, 2],
          fill: false,
        });
      }
      return ds;
    },
    motionChartData() {
      const lineColor = "#1976D2"; // primary

      return {
        yLabels: ["", "Motion", ""],
        datasets: [
          {
            data: this.motionEvents,
            label: "Motion Detected",
            backgroundColor: lineColor,
            borderColor: lineColor,
            pointBackgroundColor: lineColor,
            pointBorderColor: lineColor,
            radius: 5,
            hoverRadius: 7,
            showLine: false,
          },
        ],
      };
    },
  },
  apollo: {
    smartZones: {
      query: organizationSmartZones,
      variables() {
        return {
          id: this.currentFacilityId,
          sort: "name",
        };
      },
      update({ smartZones }) {
        const selectableZones = [];

        smartZones.reduce((acc, zone) => {
          // must have devices and climate config enabled
          if (zone.devices.length) {
            if (zone.profile.climateConfig?.disabled === false) {
              acc.push({ id: zone.id, name: zone.name });
            }
          }

          return acc;
        }, selectableZones);

        if (selectableZones.length) {
          this.selectedZone = selectableZones[0].id;
          this.selectedChanged();
        }

        return selectableZones;
      },
    },
  },
  watch: {
    dateRange(value) {
      this.selectedChanged();
    },
  },
  methods: {
    async selectedChanged() {
      if (!this.selectedZone) return;

      try {
        let response = await this.$apollo.query({
          query: leanOrganization,
          // ensure we have the latest data, or allow refresh to cancel changes
          variables: {
            id: this.selectedZone,
          },
          update({ organization }) {
            return organization;
          },
        });
        this.selectedZoneData = response.data.organization;
        this.queryDeviceEvents(this.selectedZoneData.devices[0].id);
      } catch (error) {
        console.error(error);
      }
    },
    async queryDeviceEvents(deviceId) {
      try {
        let response = await this.$apollo.query({
          query: deviceTelemetryEvents,
          variables: {
            deviceId: deviceId,
            dateTimeFilter: this.dateRange,
          },
        });
        let modded_events = response.data.events.reduce(
          (acc, event) => {
            const payload = JSON.parse(event.data);
            if (
              payload.humid_pcnt != undefined &&
              payload.temp_celcius != undefined
            ) {
              acc.humidityEvents.push({
                y: payload.humid_pcnt,
                x: new Date(payload.timestamp).toISOString(),
              });
              acc.tempEvents.push({
                y: Math.floor(celsiusToFahrenheit(payload.temp_celcius)),
                x: new Date(payload.timestamp).toISOString(),
              });
            }
            return acc;
          },
          {
            tempEvents: [],
            humidityEvents: [],
          }
        );
        if (this.selectedZoneData.profile.climateConfig) {
          const profile = this.selectedZoneData.profile.climateConfig;
          let htThresh = profile.highTemperatureThreshold;
          let ltThresh = profile.lowTemperatureThreshold;
          let hhThresh = profile.highHumidityThreshold;
          let lhThresh = profile.lowHumidityThreshold;
          let htThreshData = [
            {
              y: Math.floor(celsiusToFahrenheit(htThresh)),
              x: modded_events.tempEvents[0].x,
            },
            {
              y: Math.floor(celsiusToFahrenheit(htThresh)),
              x: modded_events.tempEvents[modded_events.tempEvents.length - 1]
                .x,
            },
          ];
          let ltThreshData = [
            {
              y: Math.floor(celsiusToFahrenheit(ltThresh)),
              x: modded_events.tempEvents[0].x,
            },
            {
              y: Math.floor(celsiusToFahrenheit(ltThresh)),
              x: modded_events.tempEvents[modded_events.tempEvents.length - 1]
                .x,
            },
          ];
          let hhThreshData = [
            {
              y: hhThresh,
              x: modded_events.humidityEvents[0].x,
            },
            {
              y: hhThresh,
              x: modded_events.humidityEvents[
                modded_events.humidityEvents.length - 1
              ].x,
            },
          ];
          let lhThreshData = [
            {
              y: lhThresh,
              x: modded_events.humidityEvents[0].x,
            },
            {
              y: lhThresh,
              x: modded_events.humidityEvents[
                modded_events.humidityEvents.length - 1
              ].x,
            },
          ];

          modded_events.htThresh = htThreshData;
          modded_events.ltThresh = ltThreshData;
          modded_events.hhThresh = hhThreshData;
          modded_events.lhThresh = lhThreshData;
        }
        this.events = modded_events;
      } catch (error) {
        console.error(error);
      }
    },
    celsiusToFahrenheit,
  },
};
</script>
