<template>
  <LayoutPage title="Average Visit">
    <v-row>
      <v-col>
        <v-select
          v-model="selectedRegion"
          :items="['Grace Combs']"
          label="Select Region"
          outlined
          dense
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedFacility"
          :items="['Facility A']"
          label="Select Facility"
          outlined
          dense
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedVisit"
          label="Average no.of Visit"
          :items="period"
          item-text="name"
          outlined
          dense
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedDuration"
          label="Average Time Per Visit"
          :items="trends"
          item-text="name"
          outlined
          dense
        />
      </v-col>
      <v-col>
        <DateTimeFilter :ranges="['Today', '2d', '1m']" custom />
      </v-col>
      <v-col>
        <v-text-field label="Search" outlined dense />
      </v-col>
    </v-row>
    <div class="table-container">
      <v-data-table
        :headers="conversionTable.headers"
        :items="conversionTable.body"
        :items-per-page="5"
        class="elevation-1"
      >
        <template #body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.facilityName">
              <template v-for="(value, key) in item">
                <CustomTableCell
                  :key="key"
                  :value="value"
                  :is-highlighted="isHighlighted(key)"
                />
              </template>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import CustomTableCell from "./CustomTableCell.vue";
import { facilityData } from "./data";
import useDashboard from "./useDashboard";
import { useRoute } from "vue-router/composables";
import { computed, ref, watch, watchEffect } from "vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";

export default {
  name: "AverageVisitTable",
  components: {
    LayoutPage,
    CustomTableCell,
    DateTimeFilter,
  },
  setup() {
    const { selectedFacilityIds, filteredFacilities, dashboardData } =
      useDashboard();

    const route = useRoute();

    const highlightColumn = computed(() => {
      return route.params.highlightColumn;
    });

    const isHighlighted = (column) => {
      if (
        highlightColumn.value === ">20 mins" ||
        highlightColumn.value === "<2 mins" ||
        highlightColumn.value === "2To10" ||
        highlightColumn.value === "10To20"
      ) {
        return column === "durationOfMotion";
      } else if (
        highlightColumn.value === "<1" ||
        highlightColumn.value === ">40" ||
        highlightColumn.value === "1To20" ||
        highlightColumn.value === "2To40"
      ) {
        return column === "visits";
      }
      return column === highlightColumn.value;
    };

    const getColor = (column, value) => {
      return "rgba(10,1,131,0.8)";
    };

    const selectedVisit = ref(null);
    const selectedDuration = ref(null);
    const selectedPeriod = ref("last12Months");

    const conversionTable = computed(() => {
      // Determine which columns to show based on the selected period
      let headersToShow = [
        { text: "Organization Name", value: "organizationName" },
        { text: "Facility Name", value: "facilityName" },
        {
          text: "Smart Zone / Unit",
          value: "smartZone",
        },
        { text: "Renter", value: "renter" },
        { text: "Status", value: "status" },
        { text: "No Of Visits", value: "visits" },
        { text: "Duration Of Motion (Mins)", value: "durationOfMotion" },
      ];

      // Filter the dashboard data based on selected duration and selected visit
      let filteredData = dashboardData.value.filter((facilityData) => {
        let passesDurationFilter = true;
        let passesVisitFilter = true;

        // Apply duration filter
        switch (selectedDuration.value) {
          case "lessThan2":
            passesDurationFilter = facilityData.durationOfMotion < 2;
            break;
          case "2To10":
            passesDurationFilter =
              facilityData.durationOfMotion >= 2 &&
              facilityData.durationOfMotion <= 10;
            break;
          case "10To20":
            passesDurationFilter =
              facilityData.durationOfMotion > 10 &&
              facilityData.durationOfMotion <= 20;
            break;
          case "greaterThan20":
            passesDurationFilter = facilityData.durationOfMotion > 20;
            break;
          default:
            break;
        }

        // Apply visit filter
        switch (selectedVisit.value) {
          case "lessThan1":
            passesVisitFilter = facilityData.visits < 1;
            break;
          case "1To20":
            passesVisitFilter =
              facilityData.visits >= 1 && facilityData.visits <= 20;
            break;
          case "20To40":
            passesVisitFilter =
              facilityData.visits > 20 && facilityData.visits <= 40;
            break;
          case "greaterThan40":
            passesVisitFilter = facilityData.visits > 40;
            break;
          default:
            break;
        }

        // Return true only if the facility data passes both filters
        return passesDurationFilter && passesVisitFilter;
      });

      return {
        headers: headersToShow,
        body: filteredData.map((facilityData) => {
          let rowData = {
            organizationName: facilityData.organizationName,
            facilityName: facilityData.name,
            smartZone: facilityData.smartZone,
            renter: facilityData.renter,
            status: facilityData.status,
            visits: facilityData.visits,
            durationOfMotion: facilityData.durationOfMotion,
          };

          return rowData;
        }),
      };
    });

    watch(selectedVisit, (value) => {
      if (value === "byTrends") {
        selectedPeriod.value = "customPeriod";
      } else if (value === "byMonths") {
        selectedPeriod.value = "last12Months";
      }
    });

    watchEffect(() => {
      const newValue = highlightColumn.value;
      switch (newValue) {
        case "<2 mins":
          selectedDuration.value = "lessThan2";
          break;
        case ">20 mins":
          selectedDuration.value = "greaterThan20";
          break;
        case "2To10":
          selectedDuration.value = "2To10";
          break;
        case "10To20":
          selectedDuration.value = "10To20";
          break;
        case ">40":
          selectedVisit.value = "greaterThan40";
          break;
        case "20To40":
          selectedVisit.value = "20To40";
          break;
        case "1To20":
          selectedVisit.value = "1To20";
          break;
        case "<1":
          selectedVisit.value = "lessThan1";
          break;
        default:
          break;
      }
    });

    return {
      selectedFacilityIds,
      filteredFacilities,
      dashboardData,
      isHighlighted,
      getColor,
      highlightColumn,
      conversionTable,
      selectedPeriod,
      selectedVisit,
      selectedDuration,
    };
  },

  data() {
    return {
      trends: [
        { name: ">20 mins", value: "greaterThan20" },
        { name: "10 - 20 mins", value: "10To20" },
        { name: "2 - 10 mins", value: "2To10" },
        { name: "<2 mins", value: "lessThan2" },
      ],
      period: [
        { name: ">40", value: "greaterThan40" },
        { name: "20 - 40", value: "20To40" },
        { name: "1 - 20", value: "1To20" },
        { name: "<1 ", value: "lessThan1" },
      ],
      selectedRegion: "Grace Combs",
      selectedFacility: "Facility A",
    };
  },
  watch: {
    filteredFacilities(newFacilities) {
      this.selectedFacilityIds = newFacilities.map((facility) => facility.id);
    },
    selectedFacilityIds(facilityIds) {
      this.dashboardData = facilityData.filter((facility) =>
        facilityIds.includes(facility.id)
      );
    },
  },
  beforeMount() {
    this.selectedFacilityIds = this.filteredFacilities.map(
      (facility) => facility.id
    );
  },
};
</script>

<style scoped>
.table-container {
  max-width: 100%;
  overflow-x: auto;
}

.table-container td {
  min-width: 150px;
}

.total td {
  font-weight: bold;
}

.negative-value {
  color: red;
}

.positive-value {
  color: green;
}

.highlighted {
  background-color: rgba(14, 59, 110, 0.2); /* Highlight color */
}
</style>
