<template>
  <v-card outlined flat tile>
    <v-card-title class="pb-0 mb-0 subtitle-1">
      <v-spacer />
      <v-row dense justify="end" align="center">
        <v-col cols="12" xs="12" md="5">
          <v-select
            v-model="selectedZone"
            :disabled="loading"
            placeholder="View climate for..."
            :items="demoSmartZones"
            item-text="smart_zones"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <DateTimeFilter
            v-model="dateRange"
            :ranges="['Today', '2d', '1m']"
            custom
            default="1m"
          />
        </v-col>
      </v-row>
      <v-progress-linear :active="loading" bottom height="2" indeterminate />
    </v-card-title>
    <div>
      <img src="/demochart.png" style="width: 100%" />
      <div class="chart_time">
        <p>13 - May</p>
        <p>14 - May</p>
        <p>15 - May</p>
        <p>16 - May</p>
        <p>17 - May</p>
        <p>18 - May</p>
        <p>19 - May</p>
        <p>20 - May</p>
        <p>21 - May</p>
        <p>22 - May</p>
        <p>23 - May</p>
        <p>24 - May</p>
        <p>25 - May</p>
        <p>26 - May</p>
        <p>27 - May</p>
        <p>28 - May</p>
        <p>29 - May</p>
        <p>30 - May</p>
        <p>31 - May</p>
        <p>01 - Jun</p>
        <p>02 - Jun</p>
        <p>03 - Jun</p>
        <p>04 - Jun</p>
        <p>05 - Jun</p>
        <p>06 - Jun</p>
        <p>07 - Jun</p>
        <p>08 - Jun</p>
        <p>09 - Jun</p>
        <p>10 - Jun</p>
        <p>11 - Jun</p>
        <p>12 - Jun</p>
        <p>13 - Jun</p>
        <p>14 - Jun</p>
      </div>
    </div>
  </v-card>
</template>

<script>
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import useFacility from "../authentication/useFacility";
import { demoSmartZones } from "./PageDemoSmartZones.vue";

export default {
  name: "ZoneEnvironmentReport",
  components: {
    DateTimeFilter,
  },
  setup() {
    const { currentFacilityId, facilityTimeZone } = useFacility();
    return { currentFacilityId, facilityTimeZone, demoSmartZones };
  },
  data() {
    return {
      selectedZone: demoSmartZones[0],
      selectedZoneData: {},
      smartZones: [],
      customStartDate: null,
      startPicker: false,
      customEndDate: null,
      endPicker: false,
      dateRange: { after: undefined, before: undefined },
      loading: true,
    };
  },
  computed: {
    chartData() {
      if (!this.hasEvents) return;
      let ds = {
        datasets: [
          {
            label: "Temperature °F",
            data: this.events.tempEvents,
            backgroundColor: "transparent",
            borderColor: "Red",
            borderWidth: 2,
            pointBackgroundColor: "transparent",
            pointBorderColor: "Red",
            hoverRadius: 5,
            lineTension: 0.5,
            showLine: true,
            fill: false,
          },
          {
            label: "Humidity %",
            data: this.events.humidityEvents,
            backgroundColor: "transparent",
            borderColor: "Blue",
            borderWidth: 2,
            pointBackgroundColor: "transparent",
            pointBorderColor: "Blue",
            hoverRadius: 5,
            lineTension: 0.5,
            showLine: true,
            fill: false,
          },
        ],
      };
      if (this.events.htThresh) {
        ds.datasets.push({
          label: "Upper Temperature Threshold °F",
          data: this.events.htThresh,
          backgroundColor: "transparent",
          borderColor: "#ff6666",
          borderWidth: 2,
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          hoverRadius: 5,
          lineTension: 0.5,
          showLine: true,
          borderDash: [10, 10],
          fill: false,
        });
      }
      if (this.events.ltThresh) {
        ds.datasets.push({
          label: "Lower Temperature Threshold °F",
          data: this.events.ltThresh,
          backgroundColor: "transparent",
          borderColor: "#ff6666",
          borderWidth: 2,
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          hoverRadius: 5,
          lineTension: 0.5,
          showLine: true,
          borderDash: [10, 10],
          fill: false,
        });
      }
      if (this.events.hhThresh) {
        ds.datasets.push({
          label: "Upper Humidity Threshold %",
          data: this.events.hhThresh,
          backgroundColor: "transparent",
          borderColor: "#6666ff",
          borderWidth: 2,
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          hoverRadius: 5,
          lineTension: 0.5,
          showLine: true,
          borderDash: [5, 2],
          fill: false,
        });
      }
      if (this.events.lhThresh) {
        ds.datasets.push({
          label: "Lower Humidity Threshold %",
          data: this.events.lhThresh,
          backgroundColor: "transparent",
          borderColor: "#6666ff",
          borderWidth: 2,
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          hoverRadius: 5,
          lineTension: 0.5,
          showLine: true,
          borderDash: [5, 2],
          fill: false,
        });
      }
      return ds;
    },
    motionChartData() {
      const lineColor = "#1976D2"; // primary

      return {
        yLabels: ["", "Motion", ""],
        datasets: [
          {
            data: this.motionEvents,
            label: "Motion Detected",
            backgroundColor: lineColor,
            borderColor: lineColor,
            pointBackgroundColor: lineColor,
            pointBorderColor: lineColor,
            radius: 5,
            hoverRadius: 7,
            showLine: false,
          },
        ],
      };
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

<style scoped>
.chart_time {
  display: flex;
}
.chart_time p {
  font-size: 10px;
  transform: rotate(-25deg);
}
</style>
