<template>
  <CardLayoutV2 title="Conversion (YTD)" :loading="loading">
    <LineChart :chart-data="chartData" unit="%" />
    <v-row>
      <v-col cols="12" md="6">
        <DashboardTileV2
          top-title="MTD Conversion"
          :top-value="mtdConversions"
          top-unit="%"
          :avatar-color="SD_ACCENT"
          avatar-name="conversion"
          :loading="loading"
          @valueClick="gotToMonthlyTrends"
        />
      </v-col>
      <v-col cols="12" md="6">
        <CardComparisonTile
          heading="MTD Comparison"
          :top-title="previousYearSameMonthName"
          :top-value="previousYearSameMonthConversions"
          top-unit="%"
          :top-value-change="yearlyChange"
          :top-value-change-percent="yearlyChangePercentage"
          :bottom-title="previousMonthName"
          :bottom-value="previousMonthConversions"
          bottom-unit="%"
          :bottom-value-change="monthlyChange"
          :bottom-value-change-percent="monthlyChangePercentage"
          :loading="loading"
          @valueClick="gotToMonthlyTrends"
        />
      </v-col>
    </v-row>
  </CardLayoutV2>
</template>

<script>
import CardLayoutV2 from "@layout/CardLayoutV2.vue";
import LineChart from "./LineChart.vue";
import {
  formatMonthYear,
  isDateMatchingOffset,
  previousMonthName,
  previousYearSameMonthName,
} from "@tod-ui/helpers/datetime";
import {
  comparisonPercentage,
  groupDataByMonth,
  calculateConversionRate,
  conversionRate,
} from "../helper";
import DashboardTileV2 from "./DashboardTileV2.vue";
import CardComparisonTile from "./CardComparisonTile.vue";
import useUser from "@components/authentication/useUser";
import { SD_ACCENT } from "@tod-ui/constants/colors";

export default {
  components: {
    CardLayoutV2,
    LineChart,
    DashboardTileV2,
    CardComparisonTile,
  },
  props: {
    conversionsData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { defaultOrganization } = useUser();
    return { defaultOrganization, SD_ACCENT };
  },
  computed: {
    chartData() {
      const { chartData, chartMonths } = groupDataByMonth(
        this.filteredConversionsData,
        "month",
        conversionRate
      );
      return {
        labels: chartMonths.map((month) => formatMonthYear(month)),
        datasets: [
          {
            label: "Conversion (%)",
            backgroundColor: "rgba(255, 196, 196, 0.8)",
            borderColor: SD_ACCENT,
            tension: 0.1,
            data: chartData,
          },
        ],
      };
    },
    mtdConversions() {
      return this.calculateConversions(0, 0);
    },
    previousMonthConversions() {
      return this.calculateConversions(0, -1);
    },

    previousYearSameMonthConversions() {
      return this.calculateConversions(-1, 0);
    },
    previousMonthName() {
      return previousMonthName(this.defaultOrganization.timeZone);
    },
    previousYearSameMonthName() {
      return previousYearSameMonthName(this.defaultOrganization.timeZone);
    },
    filteredConversionsData() {
      return this.conversionsData.filter((facility) => {
        const month = new Date(facility.month);
        return this.isCurrentYearAndMonthOrEarlier(month);
      });
    },
    yearlyChangePercentage() {
      const previousYearConversions = this.previousYearSameMonthConversions;
      const currentMonthConversions = this.mtdConversions;
      return comparisonPercentage(
        currentMonthConversions,
        previousYearConversions
      );
    },
    yearlyChange() {
      const change = this.previousYearSameMonthConversions
        ? this.mtdConversions - this.previousYearSameMonthConversions
        : 0;

      return change > 0 ? "up" : change < 0 ? "down" : "";
    },
    monthlyChangePercentage() {
      const previousMonthConversions = this.previousMonthConversions;
      const currentMonthConversions = this.mtdConversions;
      return comparisonPercentage(
        currentMonthConversions,
        previousMonthConversions
      );
    },
    monthlyChange() {
      const change = this.previousMonthConversions
        ? this.mtdConversions - this.previousMonthConversions
        : 0;
      return change > 0 ? "up" : change < 0 ? "down" : "";
    },
  },
  methods: {
    calculateConversions(offsetYear = 0, offsetMonth = 0) {
      let currentActiveSubscriptions = 0;
      let totalMoveIns = 0;

      this.conversionsData.forEach((facility) => {
        const month = new Date(facility.month);
        if (isDateMatchingOffset(month, offsetYear, offsetMonth)) {
          currentActiveSubscriptions += facility.subscriptionsAdded;
          totalMoveIns += facility.totalMoveIns;
        }
      });
      if (totalMoveIns === 0) return 0;

      return calculateConversionRate(currentActiveSubscriptions, totalMoveIns);
    },
    isCurrentYearAndMonthOrEarlier(date) {
      const currentDate = new Date();
      return (
        date.getFullYear() === currentDate.getFullYear() &&
        date.getMonth() <= currentDate.getMonth()
      );
    },
    gotToMonthlyTrends(event) {
      const period = event === "mtdconversion" ? "byTrends" : "byMonths";
      this.$router.push({
        name: "MonthlyTrends",
        query: {
          report: "conversion",
          period,
          highlight_column: event,
        },
      });
    },
  },
};
</script>
