<template>
  <LayoutPage :title="['Smart Zones', zoneName]">
    <DemoDialogLayout
      v-model="isOpen"
      title="Viewing Video Clip(Last 7 days)"
      width="900px"
    >
      <PopupVideoView />
    </DemoDialogLayout>
    <DemoDialogLayout v-model="isGear" title="Sensor Settings" width="800px">
      <SensorGear
        v-model="isGear"
        :entry-config="entryConfig"
        :service-request-config="serviceRequestConfig"
        :motion-config="motionConfig"
        :traffic-config="trafficConfig"
        :organization="organization"
        :video-config="videoConfig"
        :type="type"
      />
    </DemoDialogLayout>
    <v-row dense>
      <v-col>
        <DemoCardLayout
          v-model="editingZone"
          title="Smart Zone Information"
          header-type="edit"
          is-cog="true"
        >
          <template #edit>
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(save)">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" xs="12" md="3">
                      <ValidatedTextField
                        v-model="zoneName"
                        autofocus
                        name="Smart Zone Name"
                        rules="required|min:2|max:250"
                      />
                    </v-col>
                  </v-row>
                  <v-btn depressed tile small color="success" type="submit">
                    Save Smart Zone
                  </v-btn>
                </v-card-text>
              </v-form>
            </ValidationObserver>
          </template>

          <v-row>
            <v-col cols="12" xs="12" md="3">
              <dl>
                <dt>Smart Zone</dt>
                <dd>{{ zoneName }}</dd>
              </dl>
            </v-col>
          </v-row>
        </DemoCardLayout>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DemoCardLayout title="Video">
          <img style="width: 30%" src="/cam1.png" alt="cam view" />
          <p>
            Last Incident On :
            <a @click="isOpen = true">
              Day Time Extended Motion on Tue, Apr 16, 2024, 8:12 p.m
            </a>
          </p>
        </DemoCardLayout>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <PageSensors @emitType="changeType" />
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import { ValidationObserver } from "vee-validate";

import LayoutPage from "@layout/LayoutPage.vue";
import ValidatedTextField from "../utils/ValidatedTextField.vue";
import DemoCardLayout from "./DemoCardLayout.vue";
import { celsiusToFahrenheit } from "../utils/converters";
import { inputifyProfile } from "../utils/organizations";
import { titleCase } from "@tod-ui/helpers/strings";
import { deviceLastMotion } from "../common/helpers/devices";
import useFacility from "../authentication/useFacility";
import useAlerts from "@tod-ui/composables/useAlerts";
import { UPDATE_SMART_ZONE } from "@components/smart_zones/graphql";
import PageSensors from "./DemoCardSensors.vue";
import DemoDialogLayout from "@components/demo_dashboard/DemoDialogLayout.vue";
import PopupVideoView from "./PopupVideoView.vue";
import SensorGear from "./DemoSensorGear.vue";
import { useRoute } from "vue-router/composables";
import { ref, watchEffect } from "vue";

export default {
  components: {
    ValidationObserver,
    ValidatedTextField,
    LayoutPage,
    DemoCardLayout,
    PageSensors,
    DemoDialogLayout,
    PopupVideoView,
    SensorGear,
  },
  setup() {
    const { facilityTimeZone } = useFacility();
    const { addAlert } = useAlerts();
    const route = useRoute();
    const zoneName = ref("");
    watchEffect(() => {
      zoneName.value = route.params.name;
    });
    return { facilityTimeZone, addAlert, zoneName };
  },
  data: () => ({
    type: "",
    isGear: false,
    isOpen: false,
    organization: {
      __typename: "Organization",
      devices: [
        {
          __typename: "Device",
          batteryLevel: "70.93",
          disposition: "in_service",
          entryState: null,
          externalId: "32-38-38-37-5d-30-6e-09",
          humidity: null,
          id: "a212ebe6-18db-4dd4-a847-ef44a7d1241a",
          lastContactedAt: "2024-07-15T13:19:08.000000Z",
          motionStartedAt: "2024-07-15T13:19:08.000000Z",
          motionState: "motion_started",
          name: "XP #BD00764",
          parkingState: null,
          rmaRequest: null,
          serviceRequestState: null,
          shortId: "BD00764",
          signalStrength: "5",
          status: "ok",
          subtype: "zone_defender",
          temperature: null,
          type: "xp",
        },
        {
          __typename: "Device",
          batteryLevel: "88.93",
          disposition: "in_service",
          entryState: null,
          externalId: "35-36-37-31-73-30-64-18",
          humidity: null,
          id: "3f3e48f2-7003-4d41-b158-2c391c30e20d",
          lastContactedAt: "2024-07-15T13:17:36.000000Z",
          motionStartedAt: "2024-07-15T13:17:36.000000Z",
          motionState: "motion_started",
          name: "XP #BD00851",
          parkingState: null,
          rmaRequest: null,
          serviceRequestState: null,
          shortId: "BD00851",
          signalStrength: "5",
          status: "ok",
          subtype: "zone_defender",
          temperature: null,
          type: "xp",
        },
      ],
      externalId: null,
      id: "8ff9a00c-1b12-4103-8b6d-ea091556b6b0",
      name: "Zone 2",
      parentId: "f4739af5-be30-4245-a10b-7fe637ea81b2",
      profile: {
        __typename: "ZoneProfile",
        climateConfig: {
          __typename: "ClimateConfig",
          disabled: false,
          highHumidityThreshold: "80",
          highTemperatureThreshold: "48.89",
          lowHumidityThreshold: "25",
          lowTemperatureThreshold: "26.67",
        },
        entryConfig: null,
        motionConfigs: [
          {
            __typename: "MotionConfig",
            disabled: false,
            endsAt: null,
            mode: "motion",
            schedule: "night",
            startsAt: null,
          },
          {
            __typename: "MotionConfig",
            disabled: false,
            endsAt: null,
            mode: "people_counting",
            schedule: "always",
            startsAt: null,
          },
        ],
        serviceRequestConfig: null,
        videoConfig: null,
      },
      responders: [],
      subtype: "zone",
      timeZone: "Asia/Kolkata",
      type: "zone",
      unitType: null,
    },
    incidents: [],
    dateRange: { after: undefined },
    editingZone: "",
  }),
  computed: {
    hasDevices() {
      return this.organization?.devices?.length;
    },

    climateConfigEnabled() {
      return (
        this.organization?.profile?.climateConfig &&
        !this.organization.profile.climateConfig.disabled
      );
    },
    currentHumidity() {
      if (!this.climateConfigEnabled) return null;
      if (!this.hasDevices) return null;

      // find the most recent device that has humidity
      const devices = this.organization.devices
        .filter((d) => d.humidity > 0)
        .sort(
          (a, b) =>
            Date.parse(b.lastContactedAt) - Date.parse(a.lastContactedAt)
        );

      if (devices.length) return devices[0].humidity;
      return null;
    },
    currentTemp() {
      if (!this.climateConfigEnabled) return null;
      if (!this.hasDevices) return null;

      // find the most recent device that has temperature
      const devices = this.organization.devices
        .filter((d) => d.temperature > 0)
        .sort(
          (a, b) =>
            Date.parse(b.lastContactedAt) - Date.parse(a.lastContactedAt)
        );

      if (devices.length) {
        const temp = devices[0].temperature;
        return Math.round(celsiusToFahrenheit(temp));
      }
      return null;
    },
    configProfile() {
      return this.organization?.profile;
    },
    entryConfig() {
      return this.organization?.profile?.entryConfig;
    },
    motionConfig() {
      const motionConfigs = this.organization?.profile?.motionConfigs;
      if (motionConfigs?.length) {
        return motionConfigs.find((c) => c.mode === "motion");
      }

      return null;
    },
    serviceRequestConfig() {
      return this.organization?.profile?.serviceRequestConfig;
    },
    trafficConfig: {
      get() {
        const motionConfigs = this.organization?.profile?.motionConfigs;
        if (motionConfigs?.length) {
          return motionConfigs.find((c) => c.mode === "people_counting");
        }

        return null;
      },
      set(value) {
        const configIndex =
          this.organization?.profile?.motionConfigs?.findIndex(
            (c) => c.mode === "people_counting"
          );
        if (configIndex !== undefined && configIndex !== -1) {
          this.organization.profile.motionConfigs[configIndex] = value;
        }
      },
    },
    videoConfig() {
      return this.organization?.profile?.videoConfig;
    },
  },

  methods: {
    changeType(type) {
      this.type = type;
      this.isGear = true;
    },
    async save() {
      const { id, name, type, subtype, timeZone } = this.organization;
      // we have to detach the profile from vue's reactivity
      const profile = JSON.parse(JSON.stringify(this.organization.profile));

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_SMART_ZONE,
          variables: {
            id,
            name,
            subtype,
            timeZone,
            profile: inputifyProfile(profile),
          },
        });

        this.addAlert({
          type: "success",
          message: "Smart Zone updated successfully",
          timeout: 15,
        });
        this.editingZone = "";
      } catch (error) {
        console.error(error);
        this.addAlert({
          type: "error",
          message: "There was an error updating the Smart Zone Profile",
        });
      }
    },
    titleCase,
    deviceLastMotion,
  },
};
</script>

<style lang="scss" scoped>
dl {
  dt {
    float: left;
    clear: left;
    width: 85px;
    text-align: right;
  }

  dt::after {
    content: ":";
  }

  dd {
    margin: 0 0 0 95px;
    padding: 0 0 0.5em 0;
    font-weight: bold;
  }
}

.v-expansion-panel:before {
  box-shadow: none !important;
}
</style>
