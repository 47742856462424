import Vue from "vue";
import VueRouter from "vue-router";

import FacilityWrapper from "@layout/FacilityWrapper.vue";
import ContactUs from "@components/other_pages/ContactUs.vue";
import authenticationRoutes from "@components/authentication/routes";
import dashboardRoutes from "@components/dashboard/routes";
import smartUnitsRoutes from "@components/smart_units/routes";
import smartZonesRoutes from "@components/smart_zones/routes";
import rentersRoutes from "@components/renters/routes";
import manageFacilityRoutes from "@components/manage_facility/routes";
import reportsRoutes from "@components/reports/routes";
import incidentRoutes from "@components/incident/routes";
import notificationRoutes from "@components/notifications/routes";
import helpRoutes from "@components/help/routes";
import auditLogRoutes from "@components/audit_log/routes";
import demoDashboardsRoutes from "@components/demo_dashboard/routes";
import managementSummaryRoutes from "@components/dashboard/management_summary/routes";
import demoZoneRoutes from "@components/demo_zone/routes";

Vue.use(VueRouter);

const routes = [
  ...authenticationRoutes.navigation,
  {
    path: "/organizations/:organization_id",
    name: "Facility",
    component: FacilityWrapper,
    redirect: { name: "FacilityDashboard" },
    children: [
      ...dashboardRoutes.organization,
      ...smartUnitsRoutes.organization,
      ...rentersRoutes.organization,
      ...smartZonesRoutes.organization,
      ...manageFacilityRoutes.organization,
      ...reportsRoutes.organization,
      ...incidentRoutes.organization,
      ...notificationRoutes.organization,
      ...auditLogRoutes.organization,
    ],
  },
  ...reportsRoutes.navigation,
  ...notificationRoutes.navigation,
  ...helpRoutes.navigation,
  ...demoDashboardsRoutes.navigation,
  ...managementSummaryRoutes.navigation,
  ...demoZoneRoutes.navigation,
  {
    path: "/contactus",
    name: "ContactUs",
    component: ContactUs,
  },
];

const router = new VueRouter({
  mode: "history",
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
