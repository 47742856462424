<template>
  <LayoutPage :title="['SmartZone', 'Add New Zone']">
    <v-stepper v-model="currentStep" class="my-5">
      <v-stepper-header>
        <v-stepper-step :complete="currentStep > 1" step="1"
          >Zone Information</v-stepper-step
        >
        <v-divider />
        <v-stepper-step :complete="currentStep > 2" step="2"
          >Assign Devices</v-stepper-step
        >
      </v-stepper-header>
    </v-stepper>

    <v-row>
      <v-col>
        <CardLayout
          v-if="currentStep === 1"
          style="width: 50%"
          title="Zone Information"
        >
          <v-text-field label="Name" />
          <v-select :items="['America/Chicago']" label="Time Zone" />
        </CardLayout>
        <DemoCardAvailableDevices v-if="currentStep === 2" :is-add="true" />
        <CardLayout v-if="currentStep === 3" title="Alert Recipient">
          <AddResponder />
        </CardLayout>
        <v-card-actions>
          <v-btn
            v-if="currentStep !== 1"
            color="secondary"
            @click="previousStep"
          >
            Previous
          </v-btn>
          <v-btn v-if="currentStep !== 2" color="primary" @click="nextStep">
            Next
          </v-btn>

          <v-btn
            v-if="currentStep === 2"
            color="success"
            @click="$router.go(-1)"
          >
            Save Zone
          </v-btn>
          <v-btn color="grey" @click="$router.go(-1)"> Cancel </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import CardLayout from "@layout/CardLayout.vue";
import DemoCardAvailableDevices from "./DemoCardAvailableDevices.vue";
import AddResponder from "./DemoAddResponder.vue";

export default {
  name: "PageRenterAdd",
  components: {
    LayoutPage,
    CardLayout,
    DemoCardAvailableDevices,
    AddResponder,
  },
  data() {
    return {
      currentStep: 1,
      alert: {
        show: false,
        type: null,
      },
    };
  },
  methods: {
    nextStep() {
      this.currentStep++;
    },
    previousStep() {
      this.currentStep--;
    },
    saveRecipient() {},
    saveZone() {},
  },
};
</script>
