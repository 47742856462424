<template>
  <LayoutPage title="Smart Zones">
    <DemoDialogLayout
      v-model="isOpen"
      title="Viewing Video Clip(Last 7 days)"
      width="900px"
    >
      <PopupVideoView />
    </DemoDialogLayout>
    <v-data-table :headers="headers" :items="demoSmartZones">
      <template #item="{ item }">
        <tr>
          <td>
            <ButtonView title="View" @click="gotoZone(item.smart_zones)" />
          </td>
          <td>{{ item.smart_zones }}</td>
          <td>
            <v-icon
              :class="getIconClass(item.activity_status)"
              :title="getIconTitle(item.activity_status)"
              class="mr-4"
            >
              mdi-motion-sensor
            </v-icon>
          </td>
          <td>
            <div>
              <v-icon
                :color="
                  getHumidityIconColor(item.environmental_status.split(',')[0])
                "
              >
                fas fa-raindrops
              </v-icon>
              {{ item.environmental_status.split(",")[0] }}%
              <!-- Display humidity value -->
              <v-icon
                :color="
                  getTemperatureIconColor(
                    item.environmental_status.split(',')[1]
                  )
                "
                class="ml-3"
              >
                fas fa-thermometer-half
              </v-icon>
              {{ item.environmental_status.split(",")[1] }}°C
              <!-- Display temperature value -->
            </div>
          </td>
          <td>
            <v-btn
              :disabled="item.video_status !== 'Enabled'"
              small
              depressed
              color="transparent"
              title="View Video"
              @click="isOpen = true"
            >
              <v-icon color="error" small left>fas fa-play-circle</v-icon>
              View Video
            </v-btn>
          </td>
          <td>{{ item.last_incident_type }}</td>
          <td>{{ item.last_incident }}</td>
        </tr>
      </template>
    </v-data-table>
    <template #actions>
      <v-btn color="success" @click="addNewZone">Add New Zone </v-btn>
      <v-btn class="ml-3" color="primary" @click="goToReports">Reports </v-btn>
    </template>
  </LayoutPage>
</template>

<script>
export const demoSmartZones = [
  {
    id: "8ff9a00c-1b12-4103-8b6d-ea091556b6b0",
    smart_zones: "Back Entrance Gate",
    activity_status: "Active",
    environmental_status: "50,72",
    video_status: "Enabled",
    last_incident_type: "Day Time Extended Motion",
    last_incident: "Tue, Apr 16 2024, 8:12 p.m.",
  },
  {
    id: "8ff9a00c-1b12-4103-8b6d-ea091556b6b0",
    smart_zones: "Building 2 Hallway 1",
    activity_status: "Inactive",
    environmental_status: "48,72",
    video_status: "Disabled",
    last_incident_type: "Humidity",
    last_incident: "Tue, Apr 16 2024, 8:12 p.m.",
  },
  {
    id: "8ff9a00c-1b12-4103-8b6d-ea091556b6b0",
    smart_zones: "Exterior",
    activity_status: "Inactive",
    environmental_status: "50,72",
    video_status: "Disabled",
    last_incident_type: "Temperature",
    last_incident: "Tue, Apr 16 2024, 8:12 p.m.",
  },
  {
    id: "8ff9a00c-1b12-4103-8b6d-ea091556b6b0",
    smart_zones: "Flood Sensor 1",
    activity_status: "Active",
    environmental_status: "48,72",
    video_status: "Enabled",
    last_incident_type: "Humidity, Active Motion",
    last_incident: "Tue, Apr 16 2024, 8:12 p.m.",
  },
];

import LayoutPage from "@components/layout/LayoutPage.vue";
import ButtonView from "@atoms/ButtonView.vue";
import DemoDialogLayout from "@components/demo_dashboard/DemoDialogLayout.vue";
import PopupVideoView from "./PopupVideoView.vue";

export default {
  components: {
    LayoutPage,
    ButtonView,
    DemoDialogLayout,
    PopupVideoView,
  },
  setup() {
    return {
      demoSmartZones,
    };
  },
  data() {
    return {
      isOpen: false,
      headers: [
        { text: "Details", value: "details" },
        { text: "Smart Zones", value: "smart_zones" },
        { text: "Activity Status", value: "activity_status" },
        { text: "Environmental Status", value: "environmental_status" },
        { text: "Video Status", value: "video_status" },
        { text: "Last Incident Type", value: "last_incident_type" },
        { text: "Last Incident (Date, Time)", value: "last_incident" },
      ],
    };
  },
  methods: {
    getIconClass(status) {
      return status === "Active" ? "active-icon" : "inactive-icon";
    },
    getIconTitle(status) {
      return status === "Active" ? "Motion Enabled" : "Motion Disabled";
    },
    getHumidityIconColor(humidity) {
      return parseInt(humidity) > 50 ? "error" : "success";
    },
    getTemperatureIconColor(temperature) {
      return parseInt(temperature) > 50 ? "error" : "success";
    },
    gotoZone(zone) {
      this.$router.push({
        name: "ViewDemoSmartZone",
        params: {
          name: zone,
        },
      });
    },
    addNewZone() {
      this.$router.push({
        name: "AddNewDemoZone",
      });
    },
    goToReports() {
      this.$router.push({
        name: "DemoReports",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.active-icon {
  color: rgb(255, 82, 82);
}

.inactive-icon {
  color: rgb(76, 175, 80);
}
</style>
