<template>
  <LayoutPage :title="['SmartZone', 'Assigned Devices']">
    <DemoCardAvailableDevices />
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import DemoCardAvailableDevices from "./DemoCardAvailableDevices.vue";

export default {
  components: {
    LayoutPage,
    DemoCardAvailableDevices,
  },
};
</script>
