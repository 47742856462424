<template>
  <v-row>
    <v-col cols="12" xs="12" md="9">
      <v-row>
        <template v-if="type.includes('Motion')">
          <v-col v-if="entryConfig" cols="12" xs="12" md="6">
            <EntryConfigWidget :editing="true" :config="entryConfig" />
          </v-col>
          <v-col v-if="serviceRequestConfig" cols="12" xs="12" md="4">
            <ServiceRequestConfigWidget
              :editing="true"
              :config="serviceRequestConfig"
            />
          </v-col>
          <v-col v-if="motionConfig" cols="12" xs="12" md="6">
            <MotionConfigWidget :editing="true" :config="motionConfig" />
          </v-col>
        </template>
        <v-col
          v-if="['Door', 'Water', 'Video'].includes(type)"
          cols="12"
          xs="12"
          md="6"
        >
          <PeopleCountingConfigWidget :editing="true" :value="trafficConfig" />
        </v-col>
        <v-col v-if="type.includes('Humidity')" cols="12" xs="12" md="6">
          <ClimateConfigWidget
            :editing="true"
            :value="organization.profile.climateConfig"
          />
        </v-col>
      </v-row>
      <div class="d-flex justify-start mt-3" @click="closePopup">
        <v-btn color="success">Save</v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ClimateConfigWidget from "@components/fixtures/ClimateConfigWidget.vue";
import EntryConfigWidget from "@components/fixtures/EntryConfigWidget.vue";
import MotionConfigWidget from "@components/fixtures/MotionConfigWidget.vue";
import PeopleCountingConfigWidget from "@components/fixtures/PeopleCountingConfigWidget.vue";
import ServiceRequestConfigWidget from "@components/fixtures/ServiceRequestConfigWidget.vue";

export default {
  components: {
    EntryConfigWidget,
    MotionConfigWidget,
    PeopleCountingConfigWidget,
    ServiceRequestConfigWidget,
    ClimateConfigWidget,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    entryConfig: {
      type: Object,
      default: null,
    },
    serviceRequestConfig: {
      type: Object,
      default: null,
    },
    motionConfig: {
      type: Object,
      default: null,
    },
    trafficConfig: {
      type: Object,
      default: null,
    },
    organization: {
      type: Object,
      default: null,
    },
    videoConfig: {
      type: Object,
      default: null,
    },
  },

  methods: {
    closePopup() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
