<template>
  <v-card outlined flat tile>
    <v-card-title class="subtitle-1">
      <v-spacer />
      <v-row dense justify="end">
        <v-col v-if="!organizationId" cols="12" xs="12" md="4">
          <v-select
            v-model="selectedZone"
            :disabled="loading"
            placeholder="View incidents for..."
            :items="smartZones"
            item-text="name"
            item-value="id"
            single-line
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-select
            v-model="selectedType"
            :disabled="loading"
            placeholder="of type..."
            :items="incidentTypes"
            item-text="name"
            item-value="value"
            single-line
          />
        </v-col>
        <v-col v-if="!organizationId" cols="12" xs="12" md="5">
          <div class="pt-4">
            <DateTimeFilter
              v-model="dateRange"
              :ranges="['Today', '1w', '1m']"
              default="1m"
              max-range="2m"
              custom
            />
          </div>
        </v-col>
      </v-row>
      <v-progress-linear :active="loading" bottom height="2" indeterminate />
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader :loading="loading" type="image, image" height="300px">
        <ZoneIncidentReportChart :chart-data="chartData" />
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatMonthDay, formatTime12hr } from "@tod-ui/helpers/datetime";
import { titleCase } from "@tod-ui/helpers/strings";
import ZoneIncidentReportChart from "./ZoneIncidentChart.vue";
import { organizationSmartZones } from "../../graphql/organizations";
import { zoneIncidents } from "../../graphql/organizations/zone";
import useFacility from "../authentication/useFacility";
import { CHART_COLORS } from "@tod-ui/constants/colors";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";

export default {
  name: "ZoneIncidentReport",
  components: {
    ZoneIncidentReportChart,
    DateTimeFilter,
  },
  props: {
    organizationId: {
      type: String,
      default: "",
    },
    dateTime: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const { currentFacilityId, facilityTimeZone } = useFacility();
    return { currentFacilityId, facilityTimeZone };
  },
  data: () => ({
    dateRange: { after: undefined },
    incidents: [],
    zoneOptions: [],
    smartZones: [],
    incidentTypes: [
      { name: "All Types", value: "all" },
      { divider: true },
      { name: "Entry", value: "entry" },
      { name: "Motion", value: "motion" },
      { name: "Service Request", value: "service_request" },
      { name: "Temperature", value: "temperature" },
      { name: "Humidity", value: "humidity" },
    ],
    selectedType: null,
    selectedZone: null,
  }),
  computed: {
    chartData() {
      return this.generateChartData();
    },
    loading() {
      const { smartZones, incidents } = this.$apollo.queries;
      return smartZones.loading || incidents.loading;
    },
  },
  apollo: {
    smartZones: {
      query: organizationSmartZones,
      variables() {
        return {
          id: this.currentFacilityId,
          sort: "name",
        };
      },
      skip() {
        return !this.dateRange?.after;
      },
      update({ smartZones }) {
        const selectableZones = [
          {
            id: "all",
            name: "All Zones",
          },
          {
            divider: true,
          },
        ];

        smartZones.reduce((acc, zone) => {
          // must have devices and at least one incident-type config enabled
          if (zone.devices.length) {
            let enabled = false;

            if (
              zone.profile.motionConfigs?.find(
                ({ mode, disabled }) => mode === "motion" && !disabled
              )
            ) {
              enabled = true;
            } else if (
              enabled ||
              zone.profile.entryConfig?.disabled === false
            ) {
              enabled = true;
            } else if (
              enabled ||
              zone.profile.climateConfig?.disabled === false
            ) {
              enabled = true;
            } else if (
              enabled ||
              zone.profile.serviceRequestConfig?.disabled === false
            ) {
              enabled = true;
            }

            if (enabled) acc.push({ id: zone.id, name: zone.name });
          }

          return acc;
        }, selectableZones);

        return selectableZones;
      },
    },
    incidents: {
      query: zoneIncidents,
      variables() {
        const vars = {
          dateTimeFilter: this.dateTime ? this.dateTime : this.dateRange,
          sort: "startedAt asc",
        };
        if (this.organizationId) {
          vars.organizationId = this.organizationId;
        } else {
          if (this.selectedType && this.selectedType !== "all") {
            vars.type = this.selectedType;
          }

          if (!this.selectedZone || this.selectedZone === "all") {
            vars.parentId = this.currentFacilityId;
          } else {
            vars.organizationId = this.selectedZone;
          }
        }

        return vars;
      },
      update({ incidents }) {
        return incidents;
      },
      skip() {
        return this.dateTime ? !this.dateTime?.after : !this.dateRange?.after;
      },
    },
  },
  methods: {
    generateChartData() {
      const labels = [];
      const entryCounts = {};
      const motionCounts = {};
      const serviceRequestCounts = {};
      const temperatureCounts = {};
      const humidityCounts = {};

      // Check if the dateRange corresponds to "Today"
      const isSameDay = this.dateTime
        ? new Date(this.dateTime.after).toDateString() ===
          new Date(this.dateTime.before).toDateString()
        : new Date(this.dateRange.after).toDateString() ===
          new Date(this.dateRange.before).toDateString();

      // Initialize count objects for each label (date or time)
      this.incidents.forEach((incident) => {
        let label;
        if (isSameDay) {
          // Format the label to show time when the range is within the same day
          label = formatTime12hr(incident.startedAt);
        } else {
          // Format the label to show date if the range spans multiple days
          label = incident.startedAt.split("T")[0];
        }

        if (!labels.includes(label)) {
          labels.push(label);
          entryCounts[label] = 0;
          motionCounts[label] = 0;
          serviceRequestCounts[label] = 0;
          temperatureCounts[label] = 0;
          humidityCounts[label] = 0;
        }

        // Increment the count for the appropriate type
        if (incident.type === "entry") {
          entryCounts[label]++;
        } else if (incident.type === "motion") {
          motionCounts[label]++;
        } else if (incident.type === "service_request") {
          serviceRequestCounts[label]++;
        } else if (incident.type === "temperature") {
          temperatureCounts[label]++;
        } else if (incident.type === "humidity") {
          humidityCounts[label]++;
        }
      });

      // Convert the count objects into arrays
      const entryData = labels.map((label) => entryCounts[label]);
      const motionData = labels.map((label) => motionCounts[label]);
      const serviceRequestData = labels.map(
        (label) => serviceRequestCounts[label]
      );
      const temperatureData = labels.map((label) => temperatureCounts[label]);
      const humidityData = labels.map((label) => humidityCounts[label]);

      // Format the labels with the month name and year or time if it's today
      const formattedLabels = isSameDay ? labels : labels.map(formatMonthDay);

      return {
        labels: formattedLabels,
        datasets: [
          {
            label: "Entry",
            backgroundColor: CHART_COLORS[5],
            data: entryData,
          },
          {
            label: "Motion",
            backgroundColor: CHART_COLORS[0],
            data: motionData,
          },
          {
            label: "Service Request",
            backgroundColor: CHART_COLORS[2],
            data: serviceRequestData,
          },
          {
            label: "Temperature",
            backgroundColor: CHART_COLORS[3],
            data: temperatureData,
          },
          {
            label: "Humidity",
            backgroundColor: CHART_COLORS[4],
            data: humidityData,
          },
        ],
      };
    },
    titleCase,
  },
};
</script>
