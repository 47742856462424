<template>
  <div>
    <v-menu ref="menu" bottom :close-on-content-click="false">
      <template #activator="{ on, attrs }">
        <div class="cursor-pointer" v-bind="attrs" v-on="on">
          {{ localLocation }}
        </div>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in locations"
          :key="index"
          @click="selectLocation(item.title)"
        >
          <v-list-item-title class="cursor-pointer">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            class="cursor-pointer"
            @click="showAddLocation = true"
          >
            <v-btn icon>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            Add Location
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="showAddLocation">
          <div class="d-flex flex-column">
            <v-text-field
              v-model="newLocation"
              label="New Location"
              @keyup.enter="addLocation"
            />
            <div class="d-flex justify-center">
              <v-btn @click="addLocation">Add</v-btn>
              <v-btn @click="cancelAddLocation">Cancel</v-btn>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    location: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      closeOnContentClick: true,
      localLocation: this.location,
      locations: [
        { title: "North hallway A" },
        { title: "East hallway A" },
        { title: "South Hallway A" },
        { title: "Hallway A" },
      ],
      showAddLocation: false,
      newLocation: "",
    };
  },
  watch: {
    location(newLocation) {
      this.localLocation = newLocation;
    },
  },
  methods: {
    selectLocation(title) {
      this.localLocation = title;
      this.$refs.menu.isActive = false;
      this.$emit("update:location", title);
    },
    addLocation() {
      if (this.newLocation.trim() !== "") {
        this.locations.push({ title: this.newLocation.trim() });
        this.newLocation = "";
        this.showAddLocation = false;
      }
    },
    cancelAddLocation() {
      this.newLocation = "";
      this.showAddLocation = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
