<template>
  <v-row>
    <v-col cols="12" md="4">
      <CardDashboardTile
        top-title="Conversions % (MTD)"
        bottom-title="Conversions"
        top-tooltip="Frequency of smart unit move-ins in the facility"
        bottom-tooltip="Smart unit move-in / total move-in"
        :top-value="35"
        bottom-value="45/127"
        top-unit=" %"
        bottom-unit=""
        avatar-color="#D50D41"
        avatar-name="conversion"
        @topClickEvent="showConversionTableByConversionPercentage"
        @bottomClickEvent="showConversionTableByConversion"
      />
    </v-col>
    <v-col cols="12" md="4">
      <CardDashboardTile
        top-title="Lifetime Revenue Adds (MTD)"
        bottom-title="Missed Lifetime Revenue"
        top-tooltip="Revenue from new move-ins this month"
        bottom-tooltip="Lost revenue from non-smart unit move-ins this month"
        top-value="6,480"
        bottom-value="11,808"
        top-unit="$"
        bottom-unit="$"
        avatar-color="#323487"
        avatar-name="revenue"
        @topClickEvent="showRevenueDetails"
        @bottomClickEvent="showMissedDetails"
      />
    </v-col>
    <v-col cols="12" md="4">
      <CardDashboardTile
        top-title="Penetration % (MTD)"
        bottom-title="Annualized Revenue"
        top-tooltip="Total ratio of smart unit move-ins to total move-ins"
        bottom-tooltip="The revenue over a year from active subscriptions"
        :top-value="56"
        bottom-value="59,040"
        top-unit=" %"
        bottom-unit="$"
        avatar-color="#D50D41"
        avatar-name="penetration"
        @topClickEvent="goToPenetrationTop"
        @bottomClickEvent="goToPenetrationBottom"
      />
    </v-col>
  </v-row>
</template>

<script>
import CardDashboardTile from "./CardDashboardTile";
import useDashboard from "./useDashboard";

export default {
  components: {
    CardDashboardTile,
  },
  setup() {
    const {
      totalConversionsRateOfThisMonth,
      totalConversion,
      totalLifeTimeRevenueThisMonth,
      totalMissedLifeTimeRevenueThisMonth,
      totalPenetrationRate,
      totalAnnualizedLifetimeRevenue,
    } = useDashboard();

    return {
      totalConversionsRateOfThisMonth,
      totalConversion,
      totalLifeTimeRevenueThisMonth,
      totalMissedLifeTimeRevenueThisMonth,
      totalPenetrationRate,
      totalAnnualizedLifetimeRevenue,
    };
  },
  methods: {
    showConversionTableByConversionPercentage() {
      this.$router.push("/demo/management/conversion/conversionPercentage");
    },
    showConversionTableByConversion() {
      this.$router.push("/demo/management/conversion/conversion");
    },
    goToPenetrationTop(value) {
      this.$router.push("/demo/management/penetration/penetration");
    },
    goToPenetrationBottom(value) {
      this.$router.push("/demo/management/penetration/annualizedRevenue");
    },
    showRevenueDetails() {
      this.$router.push("/demo/management/revenue/lifetimeadds");
    },
    showMissedDetails() {
      this.$router.push("/demo/management/revenue/missed");
    },
    logAnnualizedRevenueClick() {
      this.$router.push("/demo/management/penetration");
    },
  },
};
</script>
