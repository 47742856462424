<template>
  <LayoutPage title="Incident Disposition Report">
    <v-row>
      <v-col>
        <v-select
          v-model="selectedRegion"
          dense
          :items="regions"
          label="Select Regions"
          outlined
        />
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="selectedFacilities"
          dense
          :items="facilities"
          label="Select Facilities"
          multiple
          outlined
          clearable
          item-text="name"
          item-value="id"
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0">{{ item.name }}</span>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ selectedFacilities.length - 1 }} others)</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-select
          v-model="selectedAssignee"
          :items="['Helen(Facility Manager)', 'John (Facility Admin)']"
          dense
          outlined
          label="Assignee"
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedDisposition"
          :items="['All', 'Open', 'Closed', 'In Progress', 'In Review']"
          dense
          outlined
          label="Incident Disposition"
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-end">
      <div class="mr-4">
        <DateTimeFilter
          v-model="dateRange"
          :ranges="['Today', '2d', '1m']"
          custom
          inverted
          time-zone="America/New_York"
        />
      </div>
      <div class="mr-4">
        <DemoIncidentFilter />
      </div>
      <div>
        <SearchField v-model="search" />
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :items-per-page="10"
      :search="search"
      class="elevation-1 custom-table"
    >
      <template #item="{ item }">
        <tr>
          <td>
            <ButtonView title="View" @click="gotoZone(item)" />
          </td>
          <td style="min-width: 150px">{{ item.incident_id }}</td>
          <td style="max-width: 300px">
            <div><strong>Facility</strong> : {{ item.facility }}</div>
            <div class="mt-2">
              <strong>Smart Unit / Zone</strong> : {{ item.smart_unit }}
            </div>
          </td>
          <td>{{ item.incident_type }}</td>
          <td>
            <div>
              {{ item.incident_disposition }}
              <span v-if="item.overdue" class="overdue-text">Overdue!</span>
            </div>
          </td>
          <td>
            <SeverityChip :severity="item.severity" />
          </td>
          <td>{{ item.renter_details }}</td>
          <td>{{ item.started_on }}</td>
          <td>{{ item.target_close_date }}</td>
          <td>{{ item.status }}</td>
        </tr>
      </template>
      <template #footer.prepend>
        <ButtonExportCSV
          class="order-last"
          :table-data="tableData"
          :headers="headers"
          filename="Incident Disposition Report"
        />
      </template>
    </v-data-table>
  </LayoutPage>
</template>

<script>
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";
import ButtonView from "@atoms/ButtonView.vue";
import SearchField from "@atoms/SearchField.vue";
import LayoutPage from "@components/layout/LayoutPage.vue";
import DemoIncidentFilter from "../DemoIncidentFilter.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import { computed, ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import SeverityChip from "./SeverityChip.vue";
import useDashboard from "../management_dashboard/useDashboard";

export default {
  components: {
    ButtonView,
    SearchField,
    LayoutPage,
    DemoIncidentFilter,
    ButtonExportCSV,
    DateTimeFilter,
    SeverityChip,
  },
  setup() {
    const { incidentInfo } = useDashboard();
    const route = useRoute();
    const router = useRouter();
    const selectedDisposition = ref("Open");
    const status = computed(() => {
      return route.params.status;
    });

    const isOverdue = computed(() => {
      return status.value.includes("overdue");
    });

    const isCurrent = computed(() => {
      return status.value.includes("current");
    });

    const gotoZone = (status) => {
      incidentInfo.value = status;
      router.push(
        `/demo/call-center/incident-info/${status.incident_disposition}&${status.severity}&${status.smart_unit}`
      );
    };

    watchEffect(() => {
      if (
        status.value === "inprogressincidents" ||
        status.value === "overdueinprogressincidents"
      ) {
        selectedDisposition.value = "In Progress";
      } else if (status.value === "inreview") {
        selectedDisposition.value = "In Review";
      }
    });

    const tableData = ref([
      {
        incident_id: "VSU-DT-001",
        smart_unit: 100,
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        incident_type: "Vacant Smart Unit Activity During Day",
        incident_disposition: "Open",
        overdue: false,
        severity: "Low",
        renter_details: "---",
        started_on: "06/13/2024, 7:00 am",
        target_close_date: "06/19/2024",
        status: "Ended",
      },
      {
        incident_id: "VSU-DT-002",
        smart_unit: 101,
        overdue: false,
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        incident_type: "Vacant Smart Unit Activity During Day",
        incident_disposition: "Closed",
        severity: "Low",
        renter_details: "---",
        target_close_date: "06/19/2024",
        started_on: "06/13/2024, 6:53 am",
        status: "Ended, No Response from Renter",
      },
      {
        incident_id: "VSU-AH-001",
        smart_unit: 102,
        overdue: false,
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        incident_type:
          "Vacant Smart Unit Activity with extended motion After Hours",
        incident_disposition: "Closed",
        severity: "Moderate",
        target_close_date: "06/19/2024",
        renter_details: "---",
        started_on: "06/13/2024, 6:36 am",
        status: "Ongoing Day Time Extended Motion",
      },
      {
        incident_id: "VSU-AH-002",
        smart_unit: 103,
        overdue: false,
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        incident_type:
          "Vacant Smart Unit Activity with extended motion After Hours",
        incident_disposition: "Open",
        severity: "Moderate",
        target_close_date: "06/19/2024",
        renter_details: "---",
        started_on: "06/13/2024, 6:34 am",
        status: "Ongoing Day Time Extended Motion",
      },
      {
        incident_id: "ASU-DT-UN-001",
        smart_unit: 104,
        overdue: false,
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        incident_type:
          "Active Tenant Smart Unit Un-Authorized Activity During Day",
        incident_disposition: "In Progress",
        severity: "Substantial",
        target_close_date: "06/19/2024",
        renter_details: "Brian Lee((510) 290-8800)",
        started_on: "06/13/2024, 5:27 am",
        status: "Ended",
      },
      {
        incident_id: "ASU-DT-UN-002",
        smart_unit: 105,
        overdue: false,
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        incident_type:
          "Active Tenant Smart Unit Un-Authorized Activity During Day",
        incident_disposition: "In Review",
        severity: "Substantial",
        target_close_date: "06/19/2024",
        renter_details: "Carrie Brown((832) 906-1160)",
        started_on: "06/13/2024, 5:12 am",
        status: "Ended",
      },
      {
        incident_id: "ASU-AH-UN-001",
        smart_unit: 106,
        overdue: false,
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        incident_type:
          "Active Tenant Smart Unit Un-Authorized Activity After Hours",
        incident_disposition: "In Progress",
        severity: "Severe",
        target_close_date: "06/13/2024",
        renter_details: "Brian Lee((510) 290-8800)",
        started_on: "06/19/2024, 1:53 am",
        status: "Ended, No Response from Renter",
      },
      {
        incident_id: "ASU-AH-UN-002",
        smart_unit: 107,
        overdue: true,
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        incident_type:
          "Active Tenant Smart Unit Un-Authorized Activity After Hours",
        incident_disposition: "Open",
        severity: "Severe",
        target_close_date: "06/15/2024",
        renter_details: "Carrie Brown((832) 906-1160)",
        started_on: "06/12/2024 10.53 pm",
        status: "Ended",
      },
      {
        smart_unit: 108,
        incident_id: "MSU-AH-NA-001",
        overdue: true,
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        incident_type: "Multi-Unit Motion Detection After Hours",
        incident_disposition: "In Review",
        severity: "Critical",
        renter_details: "Carrie Brown((832) 906-1160)",
        started_on: "06/15/2024 8:53 pm",
        target_close_date: "06/13/2024",
        status: "Ended, No Response from Renter",
      },
      {
        smart_unit: 109,
        incident_id: "MSU-AH-NA-002",
        overdue: true,
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        incident_type: "Multi-Unit Motion Detection After Hours",
        incident_disposition: "In Progress",
        severity: "Critical",
        renter_details: "Brian Lee((510) 290-8800)",
        started_on: "06/12/2024 8:12 pm",
        target_close_date: "06/15/2024",
        status: "Ended",
      },
      {
        smart_unit: "Floor2 Hallway",
        incident_id: "MUZ-DT-NA-001",
        overdue: true,
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        incident_type:
          "Smart Zone Motion / Multiple Non-Authorized Unit Motion Events During Access Hours, High Concern",
        incident_disposition: "In Progress",
        severity: "Critical",
        renter_details: "---",
        target_close_date: "06/13/2024",
        started_on: "06/12/2024 6:59 pm",
        status: "Ended",
      },
      {
        smart_unit: "Floor2 Hallway",
        incident_id: "MUZ-DT-NA-002",
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        incident_type:
          "Smart Zone Motion / Multiple Non-Authorized Unit Motion Events During Access Hours, High Concern",
        incident_disposition: "Open",
        severity: "Critical",
        renter_details: "Helen @(972)838-0720",
        started_on: "06/12/2024 6:19 pm",
        target_close_date: "06/13/2024",
        status: "Ended",
        action: "open_incidence",
        overdue: true,
      },
    ]);
    const filteredTableData = computed(() => {
      if (selectedDisposition.value === "All") {
        if (isCurrent.value) {
          return tableData.value.filter((incident) => !incident.overdue);
        } else if (isOverdue.value) {
          return tableData.value.filter((incident) => incident.overdue);
        } else {
          return tableData.value;
        }
      } else {
        if (isCurrent.value) {
          return tableData.value.filter(
            (incident) =>
              incident.incident_disposition === selectedDisposition.value &&
              !incident.overdue
          );
        } else if (isOverdue.value) {
          return tableData.value.filter(
            (incident) =>
              incident.incident_disposition === selectedDisposition.value &&
              incident.overdue
          );
        } else {
          return tableData.value.filter(
            (incident) =>
              incident.incident_disposition === selectedDisposition.value
          );
        }
      }
    });

    return {
      selectedDisposition,
      tableData: filteredTableData,
      isOverdue,
      isCurrent,
      gotoZone,
    };
  },
  data() {
    return {
      dateRange: { after: "2024-04-25T06:59:59.999Z" },
      selectedRegion: "Grace Combs",
      regions: ["Grace Combs", "Region 2", "Region 3"],
      selectedAssignee: "Helen(Facility Manager)",
      selectedFacilities: [
        { id: 1, name: "Facility 1" },
        { id: 2, name: "Facility 2" },
        { id: 3, name: "Facility 3" },
      ],
      facilities: [
        { id: 1, name: "Facility 1" },
        { id: 2, name: "Facility 2" },
        { id: 3, name: "Facility 3" },
      ],
      search: null,
      notification: {
        id: 1,
        message: "This is a test notification",
        hasResolution: true,
        isDismissible: true,
        resolutionRoute: "/resolution-route",
      },
      headers: [
        { text: "View", value: "view" },
        { text: "IncidentID", value: "incident_id" },
        // { text: "Organization", value: "organization" },
        // { text: "Facility", value: "facility" },
        // { text: "Smart Unit / Zone Location", value: "smart_unit" },
        { text: "Incident Location", value: "incident_location" },
        { text: "Incident Type", value: "incident_type" },
        { text: "Incident Disposition", value: "incident_disposition" },
        { text: "Priority", value: "severity" },
        { text: "Renter Details", value: "renter_details" },
        { text: "Started On", value: "started_on" },
        { text: "Target Close Date", value: "target_close_date" },
        { text: "Status", value: "status" },
      ],
    };
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
}

.custom-table >>> .v-data-table__wrapper table td {
  min-width: 150px;
}

.overdue-text {
  color: rgb(254, 10, 5);
  font-weight: bold;
  display: block;
  margin-top: 4px;
  font-size: 12px;
}
</style>
