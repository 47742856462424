import gql from "graphql-tag";

export const MANAGEMENT_SUMMARY = gql`
  query Dashboard($ids: [ID]!) {
    dashboard(ids: $ids) {
      id
      annualizedLifetimeRevenue
      averageSubscriptionDuration
      endedSubscriptionsThisMonth
      lifeTimeRevenue
      missedLifetimeRevenue
      name
      penetrationRate
      totalActiveSubscriptions
      totalActiveSubscriptionsThisMonth
      totalUnits
      conversion_rate
      total_move_ins
    }
  }
`;

export const MONTHLY_SUBSCRIPTIONS = gql`
  query MonthlySubscriptions($ids: [ID]!, $month: [Date]!) {
    monthlySubscriptions(facilityIds: $ids, month: $month) {
      subscriptionMonth
      subscriptionsCount
      facilityId
      facilityName
      revenueMonth
      region
    }
  }
`;

export const ALL_ACTIVE_SUBSCRIPTIONS = gql`
  query AllActiveSubscriptions($ids: [ID]!) {
    allActiveSubscriptions(facilityIds: $ids) {
      facilityId
      facilityName
      subscriptionsCount
    }
  }
`;

export const FACILITY_VISITS = gql`
  query FacilityVisits($ids: [ID]!, $month: [Date]!) {
    facilityVisits(facilityIds: $ids, month: $month) {
      facilityId
      facilityName
      incidentMonth
      avgVisitDurationMinute
      visitsNumber
    }
  }
`;

export const FACILITY_VISITS_REPORT = gql`
  query FacilityVisitsReport(
    $avgTimeFilter: RangeFilter
    $dateFilter: DateFilter!
    $facilityIds: [ID]!
    $numberOfVisitsFilter: RangeFilter
  ) {
    facilityVisitsReport(
      avgTimeFilter: $avgTimeFilter
      dateFilter: $dateFilter
      facilityIds: $facilityIds
      numberOfVisitsFilter: $numberOfVisitsFilter
    ) {
      avgVisitDurationMinute
      facilityName
      visitsNumber
      responderName
      organizationName
      region
    }
  }
`;

export const DAILY_SUBSCRIPTIONS = gql`
  query DailySubscriptions($facilityIds: [ID]!, $reportDate: Date!) {
    dailySubscriptions(facilityIds: $facilityIds, reportDate: $reportDate) {
      addsOnDate
      addsPrevDay
      facilityId
      facilityName
    }
  }
`;

export const PENETRATIONS_AND_ANNUALIZED_LIFETIME_REVENUE = gql`
  query penetrationsReport($facilityIds: [ID]!, $month: [Date]!) {
    penetrationsReport(facilityIds: $facilityIds, month: $month) {
      annualizedLifetimeRevenue
      facilityId
      facilityName
      netActiveSubscriptionsInMonth
      penetrationRate
      organizationPath
      totalUnits
      region
    }
  }
`;

export const REVENUE_REPORT = gql`
  query RevenueReport($facilityIds: [ID]!, $month: [Date]!) {
    revenueReport(facilityIds: $facilityIds, month: $month) {
      averageSubscriptionDuration
      facilityId
      facilityName
      lifetimeRevenueAdds
      missedPotentialRevenue
      missedSubscriptionsInMonth
      moveInsInMonth
      netAddedSubscriptionsInMonth
      organizationPath
      region
    }
  }
`;

export const MONTHLY_CONVERSIONS = gql`
  query MonthlyConversions($ids: [ID]!, $month: [Date]!) {
    monthlyConversions(facilityIds: $ids, month: $month) {
      facilityId
      conversionRate
      facilityName
      month
      organizationPath
      subscriptionsAdded
      totalMoveIns
      region
    }
  }
`;
