import gql from "graphql-tag";
export const AUTH_STATUS = gql`
  query {
    EenAuthStatus {
      accessToken
      expiresIn
      receivedAt
      refreshToken
      scope
      tokenType
    }
  }
`;
