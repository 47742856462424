<template>
  <span :style="arrowStyle">
    <v-icon :color="color"
      >{{
        direction === "up" ? "mdi-arrow-top-left" : "mdi-arrow-bottom-right"
      }}
    </v-icon>
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "ArrowIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
    direction: {
      type: String,
      required: true,
    },
  },
  computed: {
    arrowStyle() {
      return {
        backgroundColor:
          this.direction === "up"
            ? "rgba(42, 170, 138, 0.1)"
            : "rgba(220, 20, 60, 0.1)",
        borderRadius: "10px",
        padding: "2px",
      };
    },
  },
};
</script>
