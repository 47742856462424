<template>
  <div>
    <CardLayout title="Incident Information">
      <IncidentHistory
        v-model="isIncidentHistoryOpen"
        :task="selectedTask"
        :selected-disposition="selectedDisposition"
      />
      <AddTaskDialog
        v-model="isOpen"
        :severity="selectedSeverity"
        :due-date="date"
        :incident-id="incident.id"
        @add-task="addTask"
      />
      <dl
        v-if="incident"
        class="property-list my-4"
        :class="{ 'two-columns': !singleColumn }"
      >
        <dt>Incident ID</dt>
        <dd>
          {{ incidentInfo.incident_id }}
        </dd>
        <dt>Organization</dt>
        <dd>
          {{ incidentInfo.organization }}
        </dd>
        <dt>Incident Type</dt>
        <dd>
          {{ incidentInfo.incident_type }}
        </dd>
        <dt>Facility</dt>
        <dd>
          {{ incidentInfo.facility }}
        </dd>
        <dt>Incident Disposition</dt>
        <dd>
          {{ incidentInfo.incident_disposition }}
        </dd>
        <dt>Smart Unit / Zone</dt>
        <dd>
          {{ incidentInfo.smart_unit }}
        </dd>

        <dt>Priority</dt>
        <dd>
          <SeverityChip :severity="selectedSeverity" />
        </dd>
        <dt>Renter</dt>
        <dd>
          {{ incidentInfo.renter_details }}
        </dd>
        <dt>Incident Started On</dt>
        <dd>{{ incidentInfo.started_on }}</dd>
        <dt>Incident Due Date</dt>
        <dd>{{ incidentInfo.target_close_date }}</dd>
        <dt>Current Incident Status</dt>
        <dd>
          <IconIncidentStatus small :incident="incident" />
          {{ incidentInfo.status }}
        </dd>
      </dl>
      <div class="d-flex justify-end">
        <v-btn class="mr-4 pa-4" color="primary" @click="openDialog"
          ><v-icon>mdi-plus</v-icon> Add Task</v-btn
        >
        <v-btn color="grey lighten-2" @click="$router.go(-1)"> Done </v-btn>
      </div>
    </CardLayout>

    <CardLayout v-if="tasks.length > 0" title="Task History">
      <v-row class="cursor-pointer">
        <v-col
          v-for="task in tasks"
          :key="task.id"
          cols="3"
          @click="openIncidentHistory(task)"
        >
          <CardLayout :title="task.name">
            <dl class="property-list my-4">
              <dt>Task ID</dt>
              <dd>{{ task.id }}</dd>
              <dt>Priority</dt>
              <dd>
                <SeverityChip :severity="task.priority" />
              </dd>
              <dt>Assignee</dt>
              <dd>{{ task.assignee }}</dd>
              <dt>Due Date</dt>
              <dd>{{ task.dueDate }}</dd>
            </dl>
          </CardLayout>
        </v-col>
      </v-row>
    </CardLayout>
  </div>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import IconIncidentStatus from "@atoms/IconIncidentStatus.vue";

import useIncident from "@components/incident/useIncident";
import { useRoute } from "vue-router/composables";
import { computed, ref, watchEffect } from "vue";
import AddTaskDialog from "./call-center-dashboard/AddTaskDialog.vue";
import IncidentHistory from "./call-center-dashboard/IncidentHistory.vue";
import SeverityChip from "./call-center-dashboard/SeverityChip.vue";
import useDashboard from "./management_dashboard/useDashboard";
export default {
  name: "CardIncidentInfo",
  components: {
    CardLayout,
    IconIncidentStatus,
    CardLayout,
    AddTaskDialog,
    IncidentHistory,
    SeverityChip,
  },
  props: {
    isAssignToManager: {
      type: Boolean,
      default: false,
    },
    singleColumn: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { loading } = useIncident();
    const { incidentInfo } = useDashboard();
    const route = useRoute();
    const selectedSeverity = ref("Critical");
    const selectedDisposition = ref("Open");
    const selectedUnit = ref("101");
    const status = computed(() => {
      return route.params.status;
    });
    watchEffect(() => {
      const statusArr = status.value.split("&");
      selectedDisposition.value = statusArr[0];
      selectedSeverity.value = statusArr[1];
      selectedUnit.value = statusArr[2];
    });

    return {
      loading,
      selectedSeverity,
      selectedDisposition,
      selectedUnit,
      incidentInfo,
    };
  },
  data() {
    return {
      isIncidentHistoryOpen: false,
      tasks: [],
      selectedTask: {
        id: "INC001",
        name: "Active Motion",
        priority: "High",
        assignee: "Helen(Facility Manager)",
        due_date: "16/06/2024",
        comments: "This is a comment",
      },
      isOpen: false,
      date: "06-19-2024",
      selectedItem: "Closed",
      selectedAssignee: "Helen(Facility Manager)",
      incident: {
        id: "INC001",
        responder: {
          name: "Reina",
        },
        organization: {
          name: "Smart Unit",
        },
        typeName: "Active Motion",
        statusName: "Unauthorized and Not Inspected",
        startedAt: "Mon, Jul 12 2021, 2.38p.m",
        duration: "2 minutes",
        needsSurvey: false,
        status: "active",
        isVacant: false,
        extendedDuration: true,
        type: "xp",
      },
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    openIncidentHistory(task) {
      this.selectedTask = task;
      this.isIncidentHistoryOpen = true;
    },
    getColor(severity) {
      if (severity === "Critical") return "rgb(254,10,5)";
      else if (severity === "Severe") return "rgb(173,2,1)";
      else if (severity === "Substantial") return "rgb(248,127,16)";
      else if (severity === "Moderate") return "rgb(105,189,217)";
      else return "rgb(66,171,86)";
    },
    addTask(newTask) {
      this.tasks.push(newTask);
    },
  },
};
</script>

<style lang="scss" scoped>
.property-list {
  dd {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &.two-columns {
    grid-template-columns: repeat(2, max-content auto);
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>
