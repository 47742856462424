<template>
  <div style="position: relative">
    <CardLayout title="Sensors">
      <v-data-table :headers="headers" :items="devices" class="elevation-1">
        <template #item.location="{ item }">
          <DeviceLocation :location="item.location" />
        </template>
        <template #item.health="{ item }">
          <v-icon v-if="item.health === 'good'" color="green"
            >fal fa-battery-full</v-icon
          >
          <v-icon v-else-if="item.health === 'bad'" color="red"
            >fas fa-first-aid</v-icon
          >
        </template>
        <template #item.manage>
          <a @click="manageDevices">Manage Devices</a>
        </template>
        <template #item.settings="{ item }">
          <v-btn text @click="emitType(item.type)">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </CardLayout>
  </div>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import DeviceLocation from "./DemoCardDeviceLocation.vue";

export default {
  components: {
    CardLayout,
    DeviceLocation,
  },
  data() {
    return {
      headers: [
        { text: "Sensor Type", value: "type" },
        { text: "Device Location", value: "location" },
        { text: "Device ID", value: "id" },
        { text: "Health", value: "health" },
        { text: "Last Incident", value: "lastIncident" },
        { text: "Manage Devices", value: "manage", sortable: false },
        { text: "Settings", value: "settings", sortable: false },
      ],
      devices: [
        {
          type: "Motion, Temperature, Humidity",
          location: "North hallway A",
          id: "BR02096",
          health: "good",
          lastIncident: "2 months ago",
        },
        {
          type: "Door",
          location: "East hallway A",
          id: "BP00573",
          health: "good",
          lastIncident: "13 hours ago",
        },
        {
          type: "Temperature, Humidity",
          location: "South Hallway A",
          id: "BF00112",
          health: "good",
          lastIncident: "3 months ago",
        },
        {
          type: "Video",
          location: "Hallway A",
          id: "BE01005",
          health: "good",
          lastIncident: "2 days ago",
        },
        {
          type: "Motion",
          location: "Hallway A",
          id: "BP00564",
          health: "bad",
          lastIncident: "2 weeks ago",
        },
        {
          type: "Water",
          location: "Hallway A",
          id: "BP00524",
          health: "bad",
          lastIncident: "2 weeks ago",
        },
      ],
    };
  },
  methods: {
    manageDevices() {
      this.$router.push({
        name: "DemoAssignedDevices",
      });
    },
    emitType(type) {
      this.$emit("emitType", type);
    },
  },
};
</script>

<style lang="scss" scoped>
v-container {
  margin-top: 20px;
}
v-icon {
  font-size: 24px;
}
</style>
