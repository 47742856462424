import { apolloWatchQuery } from "../../apollo-client/helper";
import { ORGANIZATION_FEATURES } from "../../graphql/organizations";
import { ref } from "vue";
import useUser from "./useUser";
const organizationFeaturesLoading = ref(false);
const organizationFeatures = ref([]);
export const DASHBOARDS = {
  MANAGEMENT_SUMMARY: "management_dashboard",
  MONITORING_AND_CONTROL_HUB: "monitoring_dashboard",
  OPERATIONS_AND_SECURITY: "operations_dashboard",
};
export default function useAccess() {
  const { defaultOrganization } = useUser();
  async function fetchOrganizationFeatures(apolloClient) {
    organizationFeaturesLoading.value = true;
    apolloWatchQuery(
      apolloClient,
      {
        query: ORGANIZATION_FEATURES,
        variables: {
          id: defaultOrganization.value.id,
        },
      },
      ({ organization }, queryLoading) => {
        organizationFeaturesLoading.value = queryLoading;
        if (organization.features) {
          organizationFeatures.value = organization.features.map(
            (features) => features.feature.name
          );
        }
      }
    );
    organizationFeaturesLoading.value = false;
  }
  function canAccessFeature(feature) {
    return organizationFeatures.value.includes(feature);
  }
  return {
    fetchOrganizationFeatures,
    organizationFeaturesLoading,
    canAccessFeature,
    DASHBOARDS,
  };
}
