export const facilityData = [
  {
    id: 1,
    name: "Facility A",
    organizationName: "Grace Combs",
    smartZone: "1001",
    durationOfMotion: 1,
    visits: 0,
    status: "Ended",
    renter: "John Doe",
    currentActiveSubscriptionMTD: 3,
    totalMoveInMTD: 23,
    conversion: 13,
    subscriptionMTD: 2,
    subscriptionPrice: 12,
    avgSubscriptionDuration: 12,
    lifetimeRevenueAdds: 432,
    missedSubscriptions: 20,
    missedLifetimeRevenue: 2880,
    activeSubscription: 32,
    totalUnit: 100,
    facilityPenetrations: 32,
    annualizedRevenue: 4608,
    lastMonthConversion: -10,
    previousMonthConversion: 10,
    threeMonthsRollingAverageConversion: 30,
    june2024Conversion: 20,
    may2024Conversion: 35,
    april2024Conversion: -2,
    march2024Conversion: -2,
    february2024Conversion: -1,
    january2024Conversion: -1,
    december2023Conversion: -2,
    november2023Conversion: -1,
    october2023Conversion: -5,
    september2023Conversion: 1,
    august2023Conversion: 0,
    july2023Conversion: 0,
    currentMonthRevenue: 384,
    lastMonthRevenue: 10,
    previousMonthRevenue: 91,
    threeMonthsRollingAverageRevenue: 376,
    june2024: 2,
    may2024: 4,
    april2024: 6,
    march2024: 6,
    february2024: 19,
    january2024: 38,
    december2023: 41,
    november2023: 34,
    october2023: 25,
    september2023: 78,
    august2023: 81,
    july2023: 91,
  },
  {
    id: 2,
    name: "Facility B",
    organizationName: "Grace Combs",
    smartZone: "1002",
    durationOfMotion: 1,
    visits: 0,
    status: "Not Authorized And Not Inspected",
    renter: "Stuart Little",
    currentActiveSubscriptionMTD: 4,
    totalMoveInMTD: 11,
    conversion: 36,
    subscriptionMTD: 1,
    subscriptionPrice: 12,
    avgSubscriptionDuration: 12,
    lifetimeRevenueAdds: 576,
    missedSubscriptions: 7,
    missedLifetimeRevenue: 1003,
    activeSubscription: 53,
    totalUnit: 125,
    facilityPenetrations: 42,
    annualizedRevenue: 7632,
    lastMonthConversion: 0,
    previousMonthConversion: 36,
    threeMonthsRollingAverageConversion: 41,
    june2024Conversion: 50,
    may2024Conversion: 28,
    april2024Conversion: 0,
    march2024Conversion: 0,
    february2024Conversion: 8,
    january2024Conversion: 1,
    december2023Conversion: 0,
    november2023Conversion: 0,
    october2023Conversion: 0,
    september2023Conversion: -1,
    august2023Conversion: 0,
    july2023Conversion: 0,
    currentMonthRevenue: 636,
    lastMonthRevenue: 10,
    previousMonthRevenue: 28,
    threeMonthsRollingAverageRevenue: 628,
    june2024: 2,
    may2024: 2,
    april2024: 4,
    march2024: 8,
    february2024: 11,
    january2024: 9,
    december2023: 6,
    november2023: 9,
    october2023: 11,
    september2023: 9,
    august2023: 17,
    july2023: 28,
  },
  {
    id: 3,
    name: "Facility C",
    organizationName: "Grace Combs",
    smartZone: "209",
    durationOfMotion: 1,
    visits: 42,
    status: "Ended",
    renter: "Mary Jane",
    currentActiveSubscriptionMTD: 11,
    totalMoveInMTD: 32,
    conversion: 34,
    subscriptionMTD: 1,
    subscriptionPrice: 12,
    avgSubscriptionDuration: 12,
    lifetimeRevenueAdds: 1584,
    missedSubscriptions: 21,
    missedLifetimeRevenue: 3024,
    activeSubscription: 87,
    totalUnit: 150,
    facilityPenetrations: 58,
    annualizedRevenue: 12528,
    lastMonthConversion: 20,
    previousMonthConversion: 10,
    threeMonthsRollingAverageConversion: 25,
    june2024Conversion: 40,
    may2024Conversion: 15,
    april2024Conversion: 0,
    march2024Conversion: 0,
    february2024Conversion: 62,
    january2024Conversion: 0,
    december2023Conversion: 0,
    november2023Conversion: 0,
    october2023Conversion: 0,
    september2023Conversion: 0,
    august2023Conversion: 0,
    july2023Conversion: 0,
    currentMonthRevenue: 1044,
    lastMonthRevenue: 10,
    previousMonthRevenue: 7,
    threeMonthsRollingAverageRevenue: 1012,
    june2024: 2,
    may2024: 1,
    april2024: 10,
    march2024: 4,
    february2024: 9,
    january2024: 3,
    december2023: 3,
    november2023: 8,
    october2023: 5,
    september2023: 7,
    august2023: 8,
    july2023: 7,
  },
  {
    id: 4,
    name: "Facility D",
    organizationName: "Grace Combs",
    smartZone: "209",
    durationOfMotion: 1,
    visits: 0,
    status: "Ended",
    renter: "Mary Jane",
    currentActiveSubscriptionMTD: 12,
    totalMoveInMTD: 25,
    conversion: 48,
    subscriptionMTD: 1,
    subscriptionPrice: 12,
    avgSubscriptionDuration: 12,
    lifetimeRevenueAdds: 1728,
    missedSubscriptions: 13,
    missedLifetimeRevenue: 1872,
    activeSubscription: 128,
    totalUnit: 165,
    facilityPenetrations: 78,
    annualizedRevenue: 18432,
    lastMonthConversion: 20,
    previousMonthConversion: 10,
    threeMonthsRollingAverageConversion: 45,
    june2024Conversion: 60,
    may2024Conversion: 20,
    april2024Conversion: 0,
    march2024Conversion: 0,
    february2024Conversion: 8,
    january2024Conversion: 0,
    december2023Conversion: -1,
    november2023Conversion: 0,
    october2023Conversion: 0,
    september2023Conversion: 0,
    august2023Conversion: 0,
    july2023Conversion: -1,
    currentMonthRevenue: 1536,
    lastMonthRevenue: 10,
    previousMonthRevenue: 16,
    threeMonthsRollingAverageRevenue: 1512,
    june2024: 2,
    may2024: 1,
    april2024: 6,
    march2024: 5,
    february2024: 9,
    january2024: 9,
    december2023: 7,
    november2023: 13,
    october2023: 12,
    september2023: 9,
    august2023: 15,
    july2023: 16,
  },
  {
    id: 5,
    name: "Facility E",
    organizationName: "Grace Combs",
    smartZone: "209",
    durationOfMotion: 21,
    visits: 0,
    status: "Ended",
    renter: "Mary Jane",
    currentActiveSubscriptionMTD: 15,
    totalMoveInMTD: 36,
    conversion: 42,
    subscriptionMTD: 1,
    subscriptionPrice: 12,
    avgSubscriptionDuration: 12,
    lifetimeRevenueAdds: 2160,
    missedSubscriptions: 21,
    missedLifetimeRevenue: 3024,
    activeSubscription: 110,
    totalUnit: 200,
    facilityPenetrations: 55,
    annualizedRevenue: 15840,
    lastMonthConversion: 20,
    previousMonthConversion: 10,
    threeMonthsRollingAverageConversion: 31,
    june2024Conversion: 60,
    may2024Conversion: 30,
    april2024Conversion: 1,
    march2024Conversion: 0,
    february2024Conversion: -55,
    january2024Conversion: 0,
    december2023Conversion: 0,
    november2023Conversion: 0,
    october2023Conversion: 0,
    september2023Conversion: 0,
    august2023Conversion: 1,
    july2023Conversion: 0,
    currentMonthRevenue: 1320,
    lastMonthRevenue: 10,
    previousMonthRevenue: 15,
    threeMonthsRollingAverageRevenue: 1288,
    june2024: 2,
    may2024: 1,
    april2024: 7,
    march2024: 6,
    february2024: 8,
    january2024: 14,
    december2023: 15,
    november2023: 8,
    october2023: 28,
    september2023: 22,
    august2023: 15,
    july2023: 15,
  },
];

export const averageDuration = {
  data: {
    averageDuration: [
      {
        id: 1,
        name: "Mason facility",
        duration: 24.03,
      },
      {
        id: 2,
        name: "Johnson center",
        duration: 30.55,
      },
      {
        id: 3,
        name: "Smith warehouse",
        duration: 18.12,
      },
      {
        id: 4,
        name: "Taylor plant",
        duration: 14.45,
      },
      {
        id: 5,
        name: "Clark depot",
        duration: 8.81,
      },
      {
        id: 6,
        name: "Anderson hub",
        duration: 5.2,
      },
      {
        id: 7,
        name: "Wilson factory",
        duration: 25.98,
      },
      {
        id: 8,
        name: "Brown distribution center",
        duration: 0,
      },
      {
        id: 9,
        name: "Martinez warehouse",
        duration: 1.4,
      },
      {
        id: 10,
        name: "Garcia plant",
        duration: 1.67,
      },
    ],
  },
};
