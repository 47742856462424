<template>
  <v-card outlined flat tile>
    <v-card-title class="subtitle-1">
      <v-spacer />
      <v-row dense justify="end" align="center">
        <v-col cols="12" xs="12" md="4">
          <v-select
            v-model="selectedZone"
            :disabled="loading"
            placeholder="View incidents for..."
            :items="demoSmartZones"
            item-text="smart_zones"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <v-select
            v-model="selectedType"
            :disabled="loading"
            placeholder="of type..."
            :items="incidentTypes"
            item-text="name"
            item-value="value"
            single-line
          />
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <DateTimeFilter
            v-model="date"
            :ranges="['Today', '2d', '1m']"
            custom
            default="1m"
          />
        </v-col>
      </v-row>
      <v-progress-linear :active="loading" bottom height="2" indeterminate />
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader :loading="loading" type="image, image" height="300px">
        <StackedBarChart :chart-data="chartData" />
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import { getDaysAgo } from "@tod-ui/helpers/datetime";
import StackedBarChart from "@components/demo_dashboard/operation-and-security-dashboard/StackedBarChart.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import { demoSmartZones } from "./PageDemoSmartZones.vue";

export default {
  name: "ZoneIncidentReport",
  components: {
    StackedBarChart,
    DateTimeFilter,
  },
  setup() {
    return { demoSmartZones };
  },
  data: () => ({
    chartData: {
      labels: [
        "13 May",
        "14 May",
        "15 May",
        "16 May",
        "17 May",
        "18 May",
        "19 May",
        "20 May",
        "21 May",
        "22 May",
        "23 May",
        "24 May",
        "25 May",
        "26 May",
        "27 May",
        "28 May",
        "29 May",
        "30 May",
        "31 May",
        "01 Jun",
        "02 Jun",
        "03 Jun",
        "04 Jun",
        "05 Jun",
        "06 Jun",
        "07 Jun",
        "08 Jun",
        "09 Jun",
        "10 Jun",
        "11 Jun",
        "12 Jun",
        "13 Jun",
        "14 Jun",
      ],
      datasets: [
        {
          label: "Humidity",
          backgroundColor: "#D50D41",
          data: [
            65, 59, 80, 81, 65, 59, 80, 81, 65, 59, 80, 81, 65, 59, 80, 81, 65,
            59, 80, 81, 65, 59, 80, 81, 65, 59, 80, 81, 65, 59, 80, 81, 65, 59,
            80, 81,
          ],
        },
        {
          label: "Active Motion",
          backgroundColor: "#323487",
          data: [
            28, 48, 40, 19, 28, 48, 40, 19, 28, 48, 40, 19, 28, 48, 40, 19, 28,
            48, 40, 19, 28, 48, 40, 19, 28, 48, 40, 19, 28, 48, 40, 19, 28, 48,
            40, 19,
          ],
        },
        {
          label: "Temperature",
          backgroundColor: "rgba(10, 199, 60, 1)",
          data: [
            20, 30, 25, 35, 20, 30, 25, 35, 20, 30, 25, 35, 20, 30, 25, 35, 20,
            30, 25, 35, 20, 30, 25, 35, 20, 30, 25, 35, 20, 30, 25, 35, 20, 30,
            25, 35,
          ],
        },
      ],
    },
    zoneOptions: [],
    smartZones: [],
    incidentTypes: [
      { name: "All Types", value: "all" },
      { divider: true },
      { name: "Entry", value: "entry" },
      { name: "Motion", value: "motion" },
      { name: "Service Request", value: "service_request" },
      { name: "Temperature", value: "temperature" },
      { name: "Humidity", value: "humidity" },
    ],
    selectedType: null,
    selectedZone: demoSmartZones[0],
    date: { after: undefined, before: undefined },
    loading: true,
  }),
  computed: {
    dateRange() {
      return {
        after: getDaysAgo(7),
      };
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>
