// Provides notification utility functions
import { unref } from "vue";
import { titleCase } from "@tod-ui/helpers/strings";

export function notificationResolution(value) {
  const notification = unref(value);
  if (!notification || !notification.name || !notification.id) {
    return { name: "Notifications" };
  }

  const triggeringEntities = decodeTriggeringEntities(
    notification.triggeringEntities
  );

  switch (notification.name) {
    case "move_in_completed_without_device":
    case "move_in_completed_with_rma_device":
    case "move_out_completed_with_rma_device":
    case "move_out_completed_with_device":
    case "move_in_without_sd":
    case "pre_install":
    case "device_upgrade_immediate":
    case "device_upgrade":
      if (notification?.organization?.id && triggeringEntities?.zoneId) {
        return {
          name: "ManageSmartUnitDevices",
          params: {
            organization_id: notification.organization.id,
            zone_id: triggeringEntities.zoneId,
          },
        };
      }
      break;
    case "contact_method_failing":
      if (notification?.organization?.id && triggeringEntities.userId) {
        return {
          name: "ViewRenter",
          params: {
            organization_id: notification.organization.id,
            renter_id: triggeringEntities.userId,
          },
        };
      }
      break;
  }
  return viewNotification(notification);
}

export function decodeTriggeringEntities(entities) {
  return entities.reduce((acc, entityJson) => {
    const entity = JSON.parse(entityJson);
    switch (entity.type) {
      case "organization":
        return {
          zoneId: entity.id,
          zoneName: entity.name,
          ...acc,
        };
      case "billing_account":
        return {
          renterId: entity.id,
          renterName: entity.name,
          ...acc,
        };
      case "billing_account_organization":
        return {
          organizationId: entity.id,
          ...acc,
        };
      case "user":
        return {
          userId: entity.id,
          userFirstName: entity?.first_name,
          userLastName: entity?.last_name,
          ...acc,
        };
      case "contact_method":
        return {
          contactMethodId: entity.cm_id,
          contactMethodValue: entity.cm_value,
          contactMethodType: entity.cm_type,
          contactMethodIsDefault: entity.cm_is_default,
          ...acc,
        };
    }
    return acc;
  }, {});
}

export function viewNotification(notification) {
  return {
    name: "Notifications",
    params: { id: notification.id },
  };
}

export function notificationDismissible(notification) {
  return !unref(notification)?.automatedResolution;
}

let documentTitleIntervalHandler = null,
  originalDocumentTitle = "";

export function restoreDocumentTitle() {
  if (documentTitleIntervalHandler) {
    clearInterval(documentTitleIntervalHandler);
    documentTitleIntervalHandler = null;
    document.title = originalDocumentTitle;
    originalDocumentTitle = "";
  }
}

export function flashDocumentTitle() {
  if (documentTitleIntervalHandler) return;

  originalDocumentTitle = document.title;
  documentTitleIntervalHandler = setInterval(() => {
    document.title =
      originalDocumentTitle === document.title
        ? "🔴 New Notification"
        : originalDocumentTitle;
  }, 1500);
}

export const NOTIFICATION_TEMPLATES = [
  {
    value: "unhealthy_device_requires_visit",
    label: "Unhealthy Device, Requires Visit",
    severity: "error",
  },
  {
    value: "unhealthy_device_with_interruption_requires_visit",
    label: "Unhealthy Device with Service Interruption, Requires Visit",
    severity: "error",
  },
  {
    value: "unhealthy_device_no_visit",
    label: "Unhealthy Device with Service Interruption, No Visit Required",
    severity: "warn",
  },
  {
    value: "temporary_service_interruption",
    label: "Temporary Service Interruption",
    severity: "warn",
  },
  {
    value: "false_negative_alert_acknowledgement",
    label: "False Negative Alert Acknowledgement",
    severity: "warn",
  },
  {
    value: "false_positive_alert_acknowledgement",
    label: "False Positive Alert Acknowledgement",
    severity: "info",
  },
  {
    value: "customer_unit_work_started",
    label: "Customer Unit, Work Started",
    severity: "info",
  },
  {
    value: "customer_unit_work_ended",
    label: "Customer Unit, Work Ended",
    severity: "info",
  },
];

export function templateLabel(name) {
  const template = NOTIFICATION_TEMPLATES.find((t) => t.value === name);
  if (template) {
    return template.label;
  }

  return titleCase(name);
}

export function templateSeverity(name) {
  const template = NOTIFICATION_TEMPLATES.find((t) => t.value === name);
  if (template) {
    return template.severity;
  }

  return "info";
}

export function severityColor(severity) {
  switch (severity) {
    case "warn":
      return "warning";
    default:
      return severity;
  }
}
