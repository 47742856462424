<template>
  <v-row>
    <v-col :md="overViewPage ? 6 : 12">
      <v-row>
        <v-col cols="12" md="6">
          <v-skeleton-loader :loading="organizationsLoading" type="list-item">
            <v-card class="card-container" @click="handleRegionSelect">
              <div v-if="selectedRegions.length" class="d-block text-truncate">
                <div>
                  {{ selectedRegions[0].name }}
                </div>
                <div
                  v-if="selectedRegions.length > 1"
                  class="grey--text caption mb-4"
                >
                  (+{{ selectedRegions.length - 1 }} others)
                </div>
              </div>
              <v-icon size="40">mdi-menu-down</v-icon>
            </v-card>
            <v-card v-if="regionSelect" class="tree-container" elevation="5">
              <div class="d-flex align-center pl-3">
                <v-checkbox v-model="allSelected" />
                Select All
              </div>
              <TreeView
                v-model="selectedRegions"
                :tree-items="organizationTree"
                :selected-items="selectedRegions"
              />
            </v-card>
          </v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="6">
          <v-skeleton-loader :loading="organizationsLoading" type="list-item">
            <v-autocomplete
              v-model="selectedFacilities"
              :items="facilities"
              label="Select Facilities"
              dense
              multiple
              outlined
              clearable
              item-text="name"
              :return-object="true"
              clear-icon="far fa-times-circle"
            >
              <template #selection="{ item, index }">
                <div v-if="index === 0" class="d-block text-truncate">
                  {{ item.name }}
                </div>
                <div v-if="index === 1" class="grey--text caption">
                  (+{{ selectedFacilities.length - 1 }} others)
                </div>
              </template>
            </v-autocomplete>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import useUser from "@components/authentication/useUser";
import useDashboard from "./useDashboard";
import { ref } from "vue";
import TreeView from "./management_summary/TreeView.vue";
import { watch } from "vue";

export default {
  components: {
    TreeView,
  },
  props: {
    overViewPage: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { defaultOrganization } = useUser();
    const {
      fetchOrganizations,
      regions,
      facilities,
      selectedRegions,
      selectedFacilities,
      organizationsLoading,
      organizationTree,
    } = useDashboard();
    const regionSelect = ref(false);
    const allSelected = ref(true);

    function handleRegionSelect() {
      return (regionSelect.value = !regionSelect.value);
    }

    watch(allSelected, (newValue) => {
      selectedRegions.value = newValue ? regions.value : [];
    });

    return {
      defaultOrganization,
      fetchOrganizations,
      regions,
      facilities,
      selectedRegions,
      selectedFacilities,
      organizationsLoading,
      organizationTree,
      allSelected,
      regionSelect,
      handleRegionSelect,
    };
  },
  beforeMount() {
    this.fetchOrganizations(this.$apollo, this.defaultOrganization.id, [
      "region",
      "facility",
      "organization",
    ]);
  },
};
</script>

<style scoped>
.tree-container {
  background-color: white;
  max-height: 450px;
  overflow-y: scroll;
  z-index: 1;
  position: absolute;
  width: 340px;
}
.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid grey;
  padding-left: 15px;
}
</style>
