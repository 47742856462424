<template>
  <div>
    <img
      src="/videoview.png"
      alt="Video View"
      style="width: 100%; height: 100%"
    />
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
