<template>
  <LayoutPage title="Regional Reports">
    <v-tabs v-model="tab">
      <v-tab key="summaries">
        {{ organizationSummaryTitle }}
      </v-tab>
      <v-tab key="monthly_subscriptions">
        {{ organizationMontlySubscriptionTitle }}
      </v-tab>
      <v-tab v-if="enableTenantLedger.value" key="enable_tenant_ledger">
        Tenant Ledger
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="summaries">
        <OrganizationFacilitySummaries />
      </v-tab-item>
      <v-tab-item key="monthly_subscriptions">
        <OrganizationFacilitySubscriptions />
      </v-tab-item>
      <v-tab-item key="enable_tenant_ledger">
        <TenantLedgerReport />
      </v-tab-item>
    </v-tabs-items>
  </LayoutPage>
</template>

<script>
import { computed, watch, ref } from "vue";
import useUser from "../authentication/useUser";
import useRouteQuery from "@components/common/useRouteQuery";

import OrganizationFacilitySummaries from "./OrganizationFacilitySummaries.vue";
import OrganizationFacilitySubscriptions from "./OrganizationFacilitySubscriptions.vue";
import TenantLedgerReport from "./TenantLedgerReport.vue";

import { ORGANIZATION_PROFILE } from "./graphql";

import LayoutPage from "@layout/LayoutPage.vue";
import useAlerts from "@tod-ui/composables/useAlerts";

export default {
  name: "PageReportsOrganization",
  components: {
    LayoutPage,
    OrganizationFacilitySummaries,
    OrganizationFacilitySubscriptions,
    TenantLedgerReport,
  },
  setup() {
    const { addAlert, clearAllAlerts } = useAlerts();

    const { defaultOrganization } = useUser();

    const { tab, accordion } = useRouteQuery();

    //reset accordion value on tab switch
    watch(tab, (_value) => {
      accordion.value = undefined;
    });

    const organizationSummaryTitle = computed(
      () => `${defaultOrganization.value.name} Summary`
    );

    const organizationMontlySubscriptionTitle = computed(
      () => `${defaultOrganization.value.name} Subscriptions`
    );

    const orgId = ref(null);
    const enableTenantLedger = ref({ value: false });

    watch(
      defaultOrganization,
      (newVal) => {
        if (newVal?.id) {
          orgId.value = newVal.id;
        }
      },
      { immediate: true }
    );

    return {
      tab,
      organizationSummaryTitle,
      organizationMontlySubscriptionTitle,
      enableTenantLedger,
      orgId,
      addAlert,
    };
  },
  apollo: {
    enableTenantLedger: {
      query: ORGANIZATION_PROFILE,
      variables() {
        return {
          id: this.orgId,
        };
      },
      update(data) {
        this.enableTenantLedger.value =
          data?.organization?.profile?.enableTenantLedger ?? false;
        return this.enableTenantLedger.value;
      },
      skip() {
        return !this.orgId;
      },
      error(error) {
        console.error("Error fetching tenant ledger report:", error);
        this.addAlert({
          type: "error",
          message: "Error fetching tenant ledger report",
          timeout: 5,
        });
      },
    },
  },
};
</script>
