<template>
  <v-card flat tile>
    <v-divider />
    <v-card-text>
      <ValidationObserver ref="form">
        <dl class="mt-6 property-list">
          <dt>Organization</dt>
          <dd>Grace Combs</dd>

          <dt>User</dt>
          <dd>
            <v-text-field dense style="width: 240px" />
          </dd>

          <dt>Contact Method</dt>
          <dd v-if="addResponder"></dd>
          <dd v-else>
            <v-row>
              <v-col cols="3">
                <v-select
                  :items="['Email', 'Mobile', 'Phone']"
                  dense
                  label="Contact Methods"
                />
              </v-col>
              <v-col cols="5">
                <v-text-field dense />
              </v-col>
            </v-row>
          </dd>

          <dt>Escalation Level</dt>
          <dd>
            <v-btn-toggle
              v-model="escalationLevel"
              mandatory
              group
              color="success"
            >
              <v-btn small :value="1">1 (Primary)</v-btn>
              <v-btn small :value="2">2 (Backup)</v-btn>
            </v-btn-toggle>
          </dd>
          <dt>During Access Hours</dt>
          <dd>
            <v-btn-toggle v-model="accessHours" multiple group color="success">
              <v-btn small value="during_access_hours">
                <v-icon small left>mdi-weather-sunny</v-icon>
                During Hours
              </v-btn>
              <v-btn small value="not_during_access_hours">
                <v-icon small left>mdi-weather-night</v-icon>
                After Hours
              </v-btn>
            </v-btn-toggle>
          </dd>
          <dt>Incident Types</dt>
          <dd>
            <v-chip-group v-model="selectedTypes" mandatory multiple column>
              <v-chip
                v-for="incidentType in validIncidentTypes"
                :key="incidentType.value"
                :value="incidentType.value"
                small
                filter
                outlined
                color="success"
              >
                {{ incidentType.text }}
              </v-chip>
            </v-chip-group>
          </dd>
        </dl>
      </ValidationObserver>
    </v-card-text>
    <div class="d-flex justify-end">
      <v-card-actions v-if="isEdit">
        <v-btn color="success"> Save </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "OrganizationResponderEditDialog",

  props: {
    responder: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    addResponder: {
      type: Boolean,
      default: false,
    },
    organization: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {},
  data() {
    const accessHoursStr = this.responder?.accessHours || "all";
    return {
      isOpen: true,
      showConfirm: false,
      inProcess: false,
      validIncidentTypes: [
        { text: "All", value: "all" },
        { text: "Battery", value: "battery" },
        { text: "Entry", value: "entry" },
        { text: "Facility Motion", value: "facility_motion" },
        { text: "Humidity", value: "humidity" },
        { text: "Motion", value: "motion" },
        { text: "Service Request", value: "service_request" },
        { text: "Signal Strength", value: "signal_strength" },
        { text: "Temperature", value: "temperature" },
        { text: "Door Sensor Incidents", value: "door_sensor_incidents" },
      ],
      selectedTypes: this.responder?.incidentTypes || ["all"],
      escalationLevel: this.responder?.escalationLevel || 1,
      accessHours:
        accessHoursStr === "all"
          ? ["during_access_hours", "not_during_access_hours"]
          : [accessHoursStr],
      organizationResponderCandidates: [],
      selectedUser: null,
      selectedContactMethod: null,
    };
  },
};
</script>

<style scoped>
dl.property-list {
  gap: 1em;
  align-items: center;
}
</style>
