<template>
  <v-list>
    <SideMenuListItem
      v-if="showDemo"
      :disabled="!showDemo"
      :facility-id="currentFacilityId"
      route="PageManagementDashboard"
      icon="fas fa-tachometer-alt"
    >
      New Dashboard
    </SideMenuListItem>
    <v-list-group v-if="showManagementSummary" color="#000">
      <template #activator>
        <v-list-item-icon>
          <v-icon small>fas fa-tachometer-alt</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Dashboards</v-list-item-title>
      </template>
      <SideMenuListItem route="ManagementSummary" icon="fas fa-map-marked-alt">
        Management Summary
      </SideMenuListItem>
      <SideMenuListItem
        :disabled="!currentFacilityId"
        :facility-id="currentFacilityId"
        route="FacilityDashboard"
        icon="as fa-warehouse"
      >
        Facility Dashboard
      </SideMenuListItem>
    </v-list-group>
    <SideMenuListItem
      v-if="!showManagementSummary"
      :disabled="!currentFacilityId"
      :facility-id="currentFacilityId"
      route="FacilityDashboard"
      icon="as fa-warehouse"
    >
      Dashboard
    </SideMenuListItem>
    <SideMenuListItem
      :disabled="!currentFacilityId"
      :facility-id="currentFacilityId"
      route="SmartUnits"
      icon="fas fa-table"
    >
      Smart Units
    </SideMenuListItem>

    <SideMenuListItem
      :disabled="!currentFacilityId"
      :facility-id="currentFacilityId"
      route="Renters"
      icon="fas fa-user-friends"
    >
      Renters
    </SideMenuListItem>

    <SideMenuListItem
      :disabled="!currentFacilityId"
      :facility-id="currentFacilityId"
      route="Inventory"
      icon="fas fa-dolly-flatbed"
    >
      Inventory
    </SideMenuListItem>

    <FeatureWrapper feature="smart_zones">
      <SideMenuListItem
        :disabled="!currentFacilityId"
        :facility-id="currentFacilityId"
        route="SmartZones"
        icon="fab fa-chromecast"
      >
        Smart Zones
      </SideMenuListItem>
    </FeatureWrapper>

    <FeatureWrapper feature="smart_zones">
      <SideMenuListItem
        v-if="showDemo"
        :disabled="!currentFacilityId"
        :facility-id="currentFacilityId"
        route="PageDemoSmartZone"
        icon="fab fa-chromecast"
      >
        New Smart Zones
      </SideMenuListItem>
    </FeatureWrapper>

    <SideMenuListItem
      :disabled="!currentFacilityId"
      :facility-id="currentFacilityId"
      route="ManageFacility"
      icon="fas fa-warehouse"
    >
      Manage Facility
    </SideMenuListItem>

    <SideMenuListItem
      :facility-id="currentFacilityId"
      :route="currentFacilityId ? 'Reports' : 'ReportsOrganization'"
      icon="fas fa-file-chart-line"
    >
      Reports
    </SideMenuListItem>

    <SideMenuListItem
      :facility-id="currentFacilityId"
      :route="currentFacilityId ? 'Notifications' : 'UserNotifications'"
      icon="fas fa-bells"
    >
      Notifications
    </SideMenuListItem>
    <SideMenuListItem
      :disabled="!currentFacilityId"
      :facility-id="currentFacilityId"
      route="AuditLog"
      icon="fas fa-history"
    >
      Audit Log
    </SideMenuListItem>

    <v-divider class="my-2" />

    <SideMenuListItem route="Help" icon="mdi-help-circle">
      Help Resources
    </SideMenuListItem>

    <SideMenuListItem route="ContactUs" icon="fa-envelope">
      Contact Us
    </SideMenuListItem>
  </v-list>
</template>

<script>
import useFacility from "../authentication/useFacility";
import SideMenuListItem from "./SideMenuListItem.vue";
import FeatureWrapper from "./FeatureWrapper.vue";
import useUser from "@components/authentication/useUser";
import useAccess from "@components/authentication/useAccess";
import { computed } from "vue";

export default {
  components: {
    FeatureWrapper,
    SideMenuListItem,
  },
  setup() {
    const { currentFacilityId } = useFacility();
    const { isFacilityManager } = useUser();
    const {
      fetchOrganizationFeatures,
      organizationFeaturesLoading,
      canAccessFeature,
      DASHBOARDS,
    } = useAccess();
    const showDemo = computed(() => {
      return [
        "dc17ec00-e95c-4de5-a4cf-3b5786ad165a",
        "7a10ad54-a6f8-4d25-b9b8-82b1efa89f1b",
      ].includes(currentFacilityId.value);
    });

    const showManagementSummary = computed(() => {
      return (
        !organizationFeaturesLoading.value &&
        !isFacilityManager.value &&
        canAccessFeature(DASHBOARDS.MANAGEMENT_SUMMARY)
      );
    });

    return {
      currentFacilityId,
      showManagementSummary,
      fetchOrganizationFeatures,
      showDemo,
    };
  },
  beforeMount() {
    this.fetchOrganizationFeatures(this.$apollo);
  },
};
</script>
