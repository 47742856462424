import DemoReports from "./DemoReports.vue";
import PageDemoSmartZones from "./PageDemoSmartZones.vue";
import PageSmartZoneDrilldown from "./DemoViewSmartZone.vue";
import PageZoneAdd from "./PageZoneAdd.vue";
import DemoPageAssignedDevices from "./DemoPageAssignedDevices.vue";

const routes = {
  navigation: [
    {
      path: "/demo/zone",
      name: "PageDemoSmartZone",
      component: PageDemoSmartZones,
    },
    {
      path: "/demo/zone/add-new",
      name: "AddNewDemoZone",
      component: PageZoneAdd,
    },
    {
      path: "/demo/zone/view-smart-zones/:name",
      name: "ViewDemoSmartZone",
      component: PageSmartZoneDrilldown,
    },
    {
      path: "/demo/zone/reports",
      name: "DemoReports",
      component: DemoReports,
    },
    {
      path: "/demo/zone/assigned-devices",
      name: "DemoAssignedDevices",
      component: DemoPageAssignedDevices,
    },
  ],
};

export default routes;
