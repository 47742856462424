<template>
  <v-row>
    <v-col cols="12" lg="6">
      <CardLayout
        v-model="inventorySearch"
        title="Available Device Inventory"
        header-type="search"
      >
        <v-simple-table>
          <thead>
            <tr>
              <th>Device Type</th>
              <th>Device #</th>
              <th>Device Health</th>
              <th>Device Location</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-if="devicesLoading">
            <tr>
              <td colspan="5">
                <em>Loading devices from inventory...</em>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="availableDevices.length > 0">
            <tr v-for="device in availableDevices" :key="device.id">
              <td>{{ device.type }}</td>
              <td>{{ device.deviceNumber }}</td>
              <td>
                <v-icon color="success">fal fa-battery-full</v-icon>
              </td>
              <td>
                <CardDeviceLocation
                  location="Add Location"
                  @update:location="updateDeviceLocation(device.id, $event)"
                />
              </td>
              <td>
                <v-btn
                  :disabled="device.status !== 'ok'"
                  depressed
                  small
                  tile
                  color="success"
                  @click="assignDevice(device)"
                  >Assign Device</v-btn
                >
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5">
                <em>No devices are available</em>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </CardLayout>
    </v-col>
    <v-col cols="12" lg="6">
      <CardLayout
        v-model="inventorySearch"
        title="Assigned Devices"
        header-type="search"
      >
        <v-simple-table>
          <thead>
            <tr>
              <th>Device Type</th>
              <th>Device #</th>
              <th>Device Health</th>
              <th>Device Location</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-if="devicesLoading">
            <tr>
              <td colspan="5">
                <em>Loading devices from inventory...</em>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="assignedDevices.length > 0">
            <tr v-for="device in assignedDevices" :key="device.id">
              <td>{{ device.type }}</td>
              <td>{{ device.deviceNumber }}</td>
              <td>
                <v-icon color="success">fal fa-battery-full</v-icon>
              </td>
              <td>
                <CardDeviceLocation
                  :location="device.location"
                  @update:location="
                    updateAssignedDeviceLocation(device.id, $event)
                  "
                />
              </td>
              <td>
                <v-btn
                  depressed
                  small
                  tile
                  color="warning"
                  @click="unassignDevice(device)"
                  >Remove Device</v-btn
                >
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5">
                <em>No devices are assigned</em>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </CardLayout>
    </v-col>
  </v-row>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import CardDeviceLocation from "./DemoCardDeviceLocation.vue";

export default {
  name: "CardDeviceInventory",
  components: { CardLayout, CardDeviceLocation },

  data: () => ({
    devicesLoading: false,
    assignedDevices: [],
    organizationDevices: [
      {
        id: 1,
        type: "Motion Sensor",
        deviceNumber: "BP00573",
        status: "ok",
        location: "",
      },
      {
        id: 2,
        type: "Motion Sensor",
        deviceNumber: "BP00574",
        status: "ok",
        location: "",
      },
      {
        id: 3,
        type: "Door Sensor",
        deviceNumber: "DR0019",
        status: "ok",
        location: "",
      },
    ],
    inventorySearch: "",
  }),

  computed: {
    availableDevices() {
      return this.organizationDevices.filter(
        (device) => !this.assignedDevices.includes(device)
      );
    },
  },

  methods: {
    updateDeviceLocation(deviceId, location) {
      const device = this.organizationDevices.find((d) => d.id === deviceId);
      if (device) {
        device.location = location;
      }
    },
    updateAssignedDeviceLocation(deviceId, location) {
      const device = this.assignedDevices.find((d) => d.id === deviceId);
      if (device) {
        device.location = location;
      }
    },
    assignDevice(device) {
      this.assignedDevices.push(device);
    },
    unassignDevice(device) {
      this.assignedDevices = this.assignedDevices.filter(
        (d) => d.id !== device.id
      );
    },
  },
};
</script>
