<template>
  <div>
    <video v-if="video" :key="videoKey" controls>
      <source :src="`data:video/mp4;base64,${video}`" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
export default {
  props: {
    video: {
      type: String,
      required: true,
    },
  },
  computed: {
    videoKey() {
      return this.video; // Use video data as key to force re-render
    },
  },
};
</script>

<style lang="scss" scoped>
video {
  width: 100%;
  height: auto;
}
</style>
