<template>
  <LayoutPage title="Smart Zones" :loading="smartZonesLoading">
    <template #actions>
      <div class="mr-4" style="min-width: 240px">
        <SearchField v-model="search" />
      </div>
      <v-btn
        color="success"
        class="action-btn"
        :to="{ name: 'AddSmartUnit', query: { subtype: 'zone' } }"
      >
        Create New Smart Zone
      </v-btn>
    </template>

    <v-data-table
      :headers="headers"
      :items="zones"
      :loading="smartZonesLoading"
      loading-text="Loading Smart Zones..."
      :search="search"
      :custom-filter="customFilter"
      :custom-sort="customSort"
      :footer-props="{ 'items-per-page-options': [10, 25, 50, 500] }"
      :sort-by="['name']"
    >
      <template #item.actions="{ item }">
        <ButtonView @click="gotoZone(item.id)" />
      </template>

      <template
        #item.activity_status="{ item: { devices, incidents, profile } }"
      >
        <ZoneStatusCluster
          :profile="profile"
          :devices="devices"
          :incidents="incidents"
          small
        />
      </template>
      <template #item.environmental_status="{ item: { devices, profile } }">
        <div v-if="hasClimateEnabled(profile)">
          <ZoneClimateCluster :profile="profile" :devices="devices" small />
        </div>
        <div v-else class="grey--text">N/A</div>
      </template>
      <template #item.video_status="{ item: { profile } }">
        <div v-if="hasVideoEnabled(profile)">
          <VideoViewer small :video-config="profile.videoConfig" />
        </div>
        <div v-else class="grey--text">
          <v-btn small depressed color="transparent">
            <v-icon color="grey" small left> fas fa-play-circle </v-icon>
            View Video
          </v-btn>
        </div>
      </template>
      <template #item.last_incident_type="{ item }">
        <div v-if="item?.lastIncident[0]">
          <IconIncidentType :incident="item?.lastIncident[0]" />
          {{ formatToIncidentType(item?.lastIncident[0]) }}
        </div>
        <div v-else class="grey--text">N/A</div>
      </template>
      <template #item.last_incident_date="{ item }">
        <div v-if="item?.lastIncident[0]">
          {{ formatToIncidentDateTime(item?.lastIncident[0]?.startedAt) }}
        </div>
        <div v-else class="grey--text">N/A</div>
      </template>
    </v-data-table>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import SearchField from "@atoms/SearchField.vue";
import VideoViewer from "../fixtures/VideoViewer.vue";
import ButtonView from "@atoms/ButtonView.vue";
import ZoneClimateCluster from "../fixtures/ZoneClimateCluster.vue";
import ZoneStatusCluster from "../fixtures/ZoneStatusCluster.vue";
import { organizationSmartZones } from "../../graphql/organizations";
import useFacility from "../authentication/useFacility";
import { formatIncidentType } from "@components/common/helpers/incident";
import { formatIncidentDateTime } from "@tod-ui/helpers/datetime";
import IconIncidentType from "@atoms/IconIncidentType.vue";

export default {
  name: "PageSmartZones",
  components: {
    SearchField,
    VideoViewer,
    ButtonView,
    ZoneClimateCluster,
    ZoneStatusCluster,
    LayoutPage,
    IconIncidentType,
  },
  setup() {
    const { currentFacilityId, facilityTimeZone } = useFacility();
    return { currentFacilityId, facilityTimeZone };
  },
  data: () => ({
    smartZones: [],

    search: "",
    headers: [
      {
        text: "Details",
        align: "start",
        filterable: false,
        sortable: false,
        value: "actions",
        width: "5%",
      },
      {
        text: "Smart Zone",
        value: "name",
        width: "15%",
      },
      {
        text: "Activity Status",
        filterable: false,
        sortable: false,
        value: "activity_status",
        width: "15%",
      },
      {
        text: "Environmental Status",
        filterable: false,
        sortable: false,
        value: "environmental_status",
        width: "15%",
      },
      {
        text: "Video Status",
        filterable: false,
        sortable: false,
        value: "video_status",
        width: "15%",
      },
      {
        text: "Last Incident Type",
        filterable: false,
        sortable: false,
        value: "last_incident_type",
        width: "15%",
      },
      {
        text: "Last Incident (Date, Time)",
        filterable: false,
        sortable: false,
        value: "last_incident_date",
        width: "15%",
      },
    ],
  }),
  computed: {
    smartZonesLoading() {
      return this.$apollo.queries.smartZones.loading;
    },

    zones() {
      if (this.smartZonesLoading) return [];

      return this.smartZones.map((zone) => {
        return {
          ...zone,
        };
      });
    },
  },
  apollo: {
    smartZones: {
      query: organizationSmartZones,
      variables() {
        return {
          id: this.currentFacilityId,
          incident_statuses: ["started", "muted"],
          incident_resolution: null,
        };
      },
      // don't cache so we get the most real-time values for climate, etc.
      fetchPolicy: "network-only",
      pollInterval: 10000,
      // filter out units w/o devices
      // TODO: What about video-only?
      update: ({ smartZones }) =>
        smartZones.filter((o) => o.devices.length > 0),
    },
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      // NOTE: item is an `organization`
      let _search = search.toLowerCase();

      // Filter on `name`
      const name = `${item.name}`;
      if (name.toLowerCase().indexOf(_search) >= 0) return true;
    },
    customSort(items, sortBy, sortDesc) {
      // sortBy and sortDesc are arrays
      const _sortBy = sortBy[0];
      const _sortDesc = sortDesc[0];

      items.sort((a, b) => {
        if (_sortBy === "name") {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();

          if (!_sortDesc) return aName < bName ? -1 : 1;
          return bName < aName ? -1 : 1;
        } else {
          // Default sort comparison
          if (!_sortDesc) return a[_sortBy] < b[_sortBy] ? -1 : 1;
          return b[_sortBy] < a[_sortBy] ? -1 : 1;
        }
      });

      return items;
    },

    // Climate Config
    hasClimateEnabled({ climateConfig }) {
      return climateConfig && !climateConfig.disabled;
    },
    hasClimateDisabled({ climateConfig }) {
      return climateConfig?.disabled;
    },
    // Door/Entry Config
    hasEntryEnabled({ entryConfig }) {
      return entryConfig && !entryConfig.disabled;
    },
    hasEntryDisabled({ entryConfig }) {
      return entryConfig?.disabled;
    },
    // Motion Config
    hasMotionEnabled({ motionConfigs }) {
      const motion = motionConfigs?.find((c) => c.mode === "motion");
      return motion && !motion.disabled;
    },
    hasMotionDisabled({ motionConfigs }) {
      const motion = motionConfigs?.find((c) => c.mode === "motion");
      return motion?.disabled;
    },

    // Video Config
    hasVideoEnabled({ videoConfig }) {
      return videoConfig && !videoConfig.disabled;
    },
    hasVideoDisabled({ videoConfig }) {
      return videoConfig?.disabled;
    },

    // navigational methods
    gotoZone(zoneId) {
      this.$router.push({
        name: "ViewSmartZone",
        params: {
          smart_zone_id: zoneId,
        },
      });
    },
    formatToIncidentType(incident) {
      return formatIncidentType(incident);
    },
    formatToIncidentDateTime(startedAt) {
      return formatIncidentDateTime(startedAt, this.facilityTimeZone);
    },
  },
};
</script>
