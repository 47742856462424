<template>
  <LayoutPage :title="incidentTitle" :loading="loading">
    <v-card outlined tile>
      <v-toolbar dense flat color="grey lighten-2">
        <v-toolbar-title class="subtitle-1">
          Incident Information
        </v-toolbar-title>
      </v-toolbar>
      <v-divider />

      <v-card-text>
        <v-skeleton-loader :loading="loading" type="article, actions">
          <v-row>
            <v-col lg="6">
              <dl v-if="incident && incident.organization">
                <dt>Smart Zone</dt>
                <dd>{{ incident.organization.name }}</dd>

                <dt>Incident Trigger</dt>
                <dd>
                  {{ incidentTriggerDescription }}
                  <IconIncidentType :incident="incident" small left />
                </dd>

                <template v-if="incident.type === 'humidity'">
                  <dt>Humidity Range</dt>
                  <dd>
                    {{ `${lowValue}${dataLabel}` }} -
                    {{ `${highValue}${dataLabel}` }}
                  </dd>
                </template>
                <template v-else-if="incident.type === 'temperature'">
                  <dt>Temperature Range</dt>
                  <dd>
                    {{ `${lowValue}${dataLabel}` }} -
                    {{ `${highValue}${dataLabel}` }}
                  </dd>
                </template>

                <dt>Start Time</dt>
                <dd>
                  {{ incident.startedAt }}
                </dd>

                <dt>End Time</dt>
                <dd>
                  {{ incident.closedAt }}
                </dd>
              </dl>
            </v-col>

            <v-col lg="12" class="mt-2">
              <IncidentChart
                v-if="incident"
                :chart-data="chartData()"
                :chart-x-axes="chartXAxes()"
                :chart-y-axes="chartYAxes()"
                :min="offsetMin"
                :max="offsetMax"
                :is-motion="incident.type === 'motion'"
                title="Incident Events"
              />
            </v-col>
          </v-row>
        </v-skeleton-loader>
      </v-card-text>
    </v-card>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import IconIncidentType from "@atoms/IconIncidentType.vue";
import IncidentChart from "../fixtures/IncidentChart.vue";

import { onUnmounted } from "vue";
import useIncident from "./useIncident";
import useZoneIncident from "./useZoneIncident";

const HUMIDITY = "humidity";
const TEMPERATURE = "temperature";
const MOTION = "motion";
const DOOR = "entry";
const SERVICE_REQUEST = "service_request";

export default {
  name: "PageZoneIncident",
  components: {
    IncidentChart,
    LayoutPage,
    IconIncidentType,
  },
  setup() {
    const {
      fetchIncident,
      cleanupIncidentRef,
      incident,
      incidentTitle,
      loading,
    } = useIncident();
    const {
      incidentEvents,
      isClimateIncident,
      lowValue,
      highValue,
      lowThreshold,
      highThreshold,
      violatedThreshold,
      dataLabel,
      incidentTriggerDescription,
    } = useZoneIncident(incident);

    onUnmounted(cleanupIncidentRef);

    return {
      fetchIncident,
      incident,
      incidentTitle,
      loading,
      incidentEvents,
      isClimateIncident,
      lowValue,
      highValue,
      lowThreshold,
      highThreshold,
      violatedThreshold,
      dataLabel,
      incidentTriggerDescription,
    };
  },
  computed: {
    incidentId() {
      return this.$route.params.incident_id;
    },
    offsetMax() {
      if (this.isClimateIncident) {
        const maxValue =
          this.violatedThreshold === this.highThreshold
            ? this.highValue
            : this.lowThreshold;
        return Math.ceil(maxValue + maxValue / 10); //maxValue + 10%
      }
      return 1;
    },
    offsetMin() {
      if (this.isClimateIncident) {
        const minValue =
          this.violatedThreshold === this.lowThreshold
            ? this.lowValue
            : this.highThreshold;
        return Math.floor(minValue - minValue / 10); //minValue - 10%
      }
      return 0;
    },
  },
  beforeMount() {
    this.fetchIncident(this.$apollo, this.incidentId);
  },
  methods: {
    chartData() {
      const { graphData } = this.incidentEvents;
      const { type } = this.incident;

      if (graphData.length <= 0) return;

      if (type === MOTION) {
        const lineColor = "#1976D2"; // primary

        return {
          yLabels: ["", "Motion", ""],
          datasets: [
            {
              data: graphData,
              label: "Motion Detected",
              backgroundColor: lineColor,
              borderColor: lineColor,
              pointBackgroundColor: lineColor,
              pointBorderColor: lineColor,
              radius: 5,
              hoverRadius: 7,
              showLine: false,
            },
          ],
        };
      } else if (type === DOOR) {
        const lineColor = "#1976D2"; // primary

        return {
          yLabels: ["", "Open", ""],
          datasets: [
            {
              data: graphData,
              label: "Open Door/Gate Detected",
              backgroundColor: lineColor,
              borderColor: lineColor,
              pointBackgroundColor: lineColor,
              pointBorderColor: lineColor,
              radius: 5,
              hoverRadius: 7,
              tension: 0,
              showLine: false,
            },
          ],
        };
      } else if (type === SERVICE_REQUEST) {
        const lineColor = "#1976D2"; // primary

        return {
          yLabels: ["", "Service Requested", ""],
          datasets: [
            {
              data: graphData,
              label: "Service Requested",
              backgroundColor: lineColor,
              borderColor: lineColor,
              pointBackgroundColor: lineColor,
              pointBorderColor: lineColor,
              radius: 5,
              hoverRadius: 7,
              tension: 0,
              showLine: false,
            },
          ],
        };
      } else {
        // high is "error", low is "icey-blue"
        const lineColor =
          this.violatedThreshold === this.highThreshold ? "#FF5252" : "#90CAF9";
        const success = "#4CAF50";

        const threshold = [
          {
            y: this.violatedThreshold,
            x: graphData[0].x,
          },
          {
            y: this.violatedThreshold,
            x: graphData[graphData.length - 1].x,
          },
        ];

        if (type === TEMPERATURE) {
          return {
            datasets: [
              {
                data: threshold,
                label: "Threshold °F",
                backgroundColor: success,
                borderColor: success,
                borderWidth: 2,
                radius: 0, // don't show points
                hoverRadius: 0,
                showLine: true,
                fill: false,
              },
              {
                data: graphData,
                label: "Temperature °F",
                backgroundColor: "transparent",
                borderColor: lineColor,
                borderWidth: 2,
                pointBackgroundColor: lineColor,
                pointBorderColor: lineColor,
                lineTension: 0.5,
                cubicInterpolationMode: "monotone",
                radius: 5,
                hoverRadius: 7,
                showLine: true,
                fill: false,
              },
            ],
          };
        }

        if (type === HUMIDITY) {
          return {
            datasets: [
              {
                data: threshold,
                label: "Threshold %",
                backgroundColor: success,
                borderColor: success,
                borderWidth: 2,
                radius: 0,
                hoverRadius: 0,
                showLine: true,
                fill: false,
              },
              {
                data: graphData,
                label: "Humidity %",
                backgroundColor: "transparent",
                borderColor: lineColor,
                borderWidth: 2,
                pointBackgroundColor: lineColor,
                pointBorderColor: lineColor,
                lineTension: 0.5,
                cubicInterpolationMode: "monotone",
                radius: 5,
                hoverRadius: 7,
                showLine: true,
                fill: false,
              },
            ],
          };
        }
      }
    },
    chartYAxes() {
      const { type } = this.incident;

      if (DOOR === type || SERVICE_REQUEST === type) {
        return [
          {
            display: false,
          },
        ];
      } else {
        return [{ distribution: "linear" }];
      }
    },
    chartXAxes() {
      const { type } = this.incident;

      if (DOOR === type || SERVICE_REQUEST === type) {
        return [
          {
            display: true,
            distribution: "linear",
            type: "time",
            time: {
              unit: "minute",
              displayFormats: {
                hour: "hh:mm a",
              },
            },
            drawTicks: true,
            ticks: {
              callback: function (val, index) {
                return index % 2 === 0 ? val : "";
              },
            },
          },
        ];
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
dl {
  dt {
    float: left;
    clear: left;
    width: 130px;
    text-align: right;
  }

  dt::after {
    content: ":";
  }

  dd {
    margin: 0 0 0 140px;
    padding: 0 0 0.5em 0;
    font-weight: bold;
  }
}
</style>
