<template>
  <CardLayoutV2 title="Revenue (YTD)" :loading="loading">
    <LineChart :chart-data="chartData" unit="$" />
    <v-row>
      <v-col cols="12" md="6">
        <DashboardTileV2
          top-title="MTD Revenue"
          :top-value="mtdRevenue"
          top-unit="$"
          :avatar-color="SD_PRIMARY"
          avatar-name="revenue"
          :loading="loading"
          @valueClick="gotToMonthlyTrends"
        />
      </v-col>
      <v-col cols="12" md="6">
        <CardComparisonTile
          heading="MTD Comparison"
          :top-title="previousYearSameMonthName"
          :top-value="previousYearSameMonthRevenue"
          top-unit="$"
          :top-value-change="yearlyChange"
          :top-value-change-percent="yearlyChangePercentage"
          :bottom-title="previousMonthName"
          :bottom-value="previousMonthRevenue"
          bottom-unit="$"
          :bottom-value-change="monthlyChange"
          :bottom-value-change-percent="monthlyChangePercentage"
          :loading="loading"
          skeleton-height="130px"
          @valueClick="gotToMonthlyTrends"
        />
      </v-col>
    </v-row>
  </CardLayoutV2>
</template>

<script>
import CardLayoutV2 from "@layout/CardLayoutV2.vue";
import LineChart from "./LineChart.vue";
import {
  formatMonthYear,
  isDateMatchingOffset,
  previousMonthName,
  previousYearSameMonthName,
} from "@tod-ui/helpers/datetime";
import { comparisonPercentage, groupDataByMonth } from "../helper";
import DashboardTileV2 from "./DashboardTileV2.vue";
import CardComparisonTile from "./CardComparisonTile.vue";
import { roundValue } from "@tod-ui/helpers/math";
import useUser from "@components/authentication/useUser";
import { SD_PRIMARY } from "@tod-ui/constants/colors";

export default {
  components: {
    CardLayoutV2,
    LineChart,
    DashboardTileV2,
    CardComparisonTile,
  },
  props: {
    revenueData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { defaultOrganization } = useUser();
    return { defaultOrganization, SD_PRIMARY };
  },
  computed: {
    chartData() {
      const { chartData, chartMonths } = groupDataByMonth(
        this.filteredRevenueData,
        "subscriptionMonth",
        "revenueMonth"
      );
      return {
        labels: chartMonths.map((month) => formatMonthYear(month)),
        datasets: [
          {
            label: "Total Revenue ($)",
            backgroundColor: "rgba(196, 200, 255, 0.8)",
            borderColor: SD_PRIMARY,
            tension: 0.6,
            data: chartData,
          },
        ],
      };
    },
    mtdRevenue() {
      return this.calculateRevenue(0, 0);
    },
    previousMonthRevenue() {
      return this.calculateRevenue(0, -1);
    },

    previousYearSameMonthRevenue() {
      return this.calculateRevenue(-1, 0);
    },
    previousMonthName() {
      return previousMonthName(this.defaultOrganization.timeZone);
    },
    previousYearSameMonthName() {
      return previousYearSameMonthName(this.defaultOrganization.timeZone);
    },
    filteredRevenueData() {
      return this.revenueData.filter((facility) => {
        const subscriptionDate = new Date(facility.subscriptionMonth);
        return this.isCurrentYearAndMonthOrEarlier(subscriptionDate);
      });
    },
    yearlyChangePercentage() {
      const previousYearRev = this.previousYearSameMonthRevenue;
      const currentRev = this.mtdRevenue;

      return comparisonPercentage(currentRev, previousYearRev);
    },
    yearlyChange() {
      const change = this.previousYearSameMonthRevenue
        ? this.mtdRevenue - this.previousYearSameMonthRevenue
        : 0;
      return change > 0 ? "up" : change < 0 ? "down" : "";
    },
    monthlyChangePercentage() {
      const previousMonthRev = this.previousMonthRevenue;
      const currentMonthRev = this.mtdRevenue;
      return comparisonPercentage(currentMonthRev, previousMonthRev);
    },
    monthlyChange() {
      const change = this.previousMonthRevenue
        ? this.mtdRevenue - this.previousMonthRevenue
        : 0;
      return change > 0 ? "up" : change < 0 ? "down" : "";
    },
  },
  methods: {
    calculateRevenue(offsetYear = 0, offsetMonth = 0) {
      return roundValue(
        this.revenueData.reduce((acc, facility) => {
          const subscriptionDate = new Date(facility.subscriptionMonth);
          if (isDateMatchingOffset(subscriptionDate, offsetYear, offsetMonth)) {
            acc += facility.revenueMonth;
          }
          return acc;
        }, 0)
      );
    },
    isCurrentYearAndMonthOrEarlier(date) {
      const currentDate = new Date();
      return (
        date.getFullYear() === currentDate.getFullYear() &&
        date.getMonth() <= currentDate.getMonth()
      );
    },
    gotToMonthlyTrends(event) {
      const period = event === "mtdrevenue" ? "byTrends" : "byMonths";
      this.$router.push({
        name: "MonthlyTrends",
        query: {
          report: "revenue",
          period,
          highlight_column: event,
        },
      });
    },
  },
};
</script>
